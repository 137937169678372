import React, { useEffect, useState, useCallback } from 'react';
import {
  useParams,
  NavLink,
  Route,
  Switch,
  useRouteMatch,
  RouteComponentProps,
} from 'react-router-dom';
import styled from 'styled-components';
import { palette } from '@stats/playbook-components';

import { apiURL, useToken } from '@/helpers/apiURL';
import { StyledSubNav } from '@/helpers/styles';
import { Tile } from '@components/Tile';

import ClientSidebar from '@components/Sidebar';

import ClientDeliveryDetail from './ClientDeliveryDetail';
import ClientDeliveries from './ClientSummaryDeliveries';
import ClientNotes from './ClientSummaryNotes';
import ClientHistory from './ClientSummaryHistory';

const ClientDetailWrapper = styled.section`
  display: flex;
  flex-direction: row;
  min-height: 100%;
  flex-grow: 1;
  color: ${palette['sp-white']};
`;

type ClientSubNavigationProps = {
  baseUrl: string;
};

const ClientSubNavigation: React.FC<ClientSubNavigationProps> = ({
  baseUrl,
}) => (
  <StyledSubNav>
    <li>
      <NavLink exact to={`${baseUrl}`}>
        Deliveries
      </NavLink>
    </li>
    <li>
      <NavLink to={`${baseUrl}/notes`}>Notes</NavLink>
    </li>
    <li>
      <NavLink to={`${baseUrl}/history`}>Change History</NavLink>
    </li>
  </StyledSubNav>
);

type ClientTileProps = {
  show: boolean;
  childnav?: React.JSX.Element[] | React.JSX.Element;
};

const ClientTile: React.FC<ClientTileProps> = ({ show, childnav, children }) =>
  show ? (
    <Tile title="Client Summary" childnav={childnav}>
      {children}
    </Tile>
  ) : (
    <>{children}</>
  );

export type ClientDetail = {
  deliveryItems: number;
  enabledDeliveryItems: number;
  enabledDeliveryMethods: [];
  createdAt: string;
  lastUpdatedAt: string;
  id: string;
  name: string;
  deliveries: [];
};

const ClientDetails: React.FC<RouteComponentProps> = (props) => {
  const params = useParams<{ clientId: string }>();
  const match = useRouteMatch();

  const [refreshClient, setRefreshClient] = useState(true);
  const [client, setClient] = useState<ClientDetail>({
    deliveryItems: 1,
    enabledDeliveryItems: 1,
    enabledDeliveryMethods: [],
    createdAt: '',
    lastUpdatedAt: '',
    id: '',
    name: '',
    deliveries: [],
  });
  const { privateFetch } = useToken();
  const getClient = useCallback(
    () => privateFetch(`${apiURL}clients/${params.clientId}`),
    [params.clientId, privateFetch]
  );

  useEffect(() => {
    let isMounted = true;
    if (refreshClient) {
      getClient()
        .then((res) => res.json())
        .then((json) => {
          if (isMounted) {
            setClient(json);
            setRefreshClient(false);
          }
        });
    }
    return () => {
      isMounted = false;
    };
  }, [getClient, refreshClient]);

  const ClientNavigation = <ClientSubNavigation baseUrl={match.url} />;
  const isClientSummary = !props.location.pathname.includes('deliveries');

  return (
    <ClientDetailWrapper>
      <ClientSidebar
        name={client?.name}
        deliveries={client?.deliveries}
        baseUrl={match.url}
      />

      <ClientTile show={isClientSummary} childnav={ClientNavigation}>
        <Switch>
          <Route path={`${match.url}/notes`}>
            <ClientNotes
              deliveries={client?.deliveries}
              clientId={client.id}
              lastUpdatedAt={client.lastUpdatedAt}
            />
          </Route>
          <Route path={`${match.url}/history`}>
            <ClientHistory clientId={client.id} />
          </Route>
          <Route
            path={`/clients/:clientId/deliveries/:deliveryId/:subtab?`}
            component={ClientDeliveryDetail}
          />
          <Route path={`${match.url}`}>
            <ClientDeliveries {...client} refreshClient={setRefreshClient} />
          </Route>
        </Switch>
      </ClientTile>
    </ClientDetailWrapper>
  );
};

export default ClientDetails;
