import React from 'react';

import { ChangeHistoryList } from '@components/ChangeHistoryList';
import { Tile } from '@components/Tile';

type OverviewChangeHistoryProps = {};

const OverviewChangeHistory: React.FC<OverviewChangeHistoryProps> = () => {
  return (
    <Tile title="Change History">
      <ChangeHistoryList historyURL="history" />
    </Tile>
  );
};

export default OverviewChangeHistory;
