import React from 'react';
import { palette } from '@stats/playbook-components';
import styled from 'styled-components';
import { useLocation } from 'react-router-dom';

const StyledDiv = styled.div`
  min-height: 100vh;
  background: ${palette['sp-light-gray-25']};
  display: flex;
  flex-direction: column;

  [role='main'] {
    min-height: 100%;
    flex-grow: 1;
    display: flex;
  }

  &.overview-wrapper {
    [role='main'] {
      height: fit-content;
      max-height: calc(100vh - 134px);
      overflow-y: auto;
    }
  }

  &.detail-wrapper {
    .tile-content {
      max-height: calc(100vh - 300px);
      max-width: calc(100vw - 272px);
      overflow-y: auto;
    }
  }
`;

export const Wrapper: React.FC = ({ children }) => {
  const location = useLocation();

  const overviewScroll =
    location.pathname.indexOf('overview') > 0
      ? 'overview-wrapper'
      : 'detail-wrapper';

  return <StyledDiv className={overviewScroll}>{children}</StyledDiv>;
};
