import React, { useCallback } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { Auth0Provider } from '@auth0/auth0-react';
import HomePage from '@/pages/HomePage';

export const history = createBrowserHistory();

type Auth0Information = {
  domain: string;
  clientId: string;
};

const App: React.FC = () => {
  const onRedirectCallback = useCallback((appState?: { returnTo?: string }) => {
    history.replace(appState?.returnTo || window.location.pathname);
  }, []);

  const targetAuth0Info: Auth0Information = {
    domain: process.env.REACT_APP_AUTH0_DOMAIN || '',
    clientId: process.env.REACT_APP_AUTH0_CLIENTID || '',
  };

  return (
    <Auth0Provider
      domain={targetAuth0Info.domain}
      clientId={targetAuth0Info.clientId}
      authorizationParams={{
        redirect_uri: window.location.origin + '/login',
        audience: process.env.REACT_APP_AUTH0_API_AUDIENCE
      }}
      onRedirectCallback={onRedirectCallback}
    >
      <Router>
        <HomePage />
      </Router>
    </Auth0Provider>
  );
};

export default App;
