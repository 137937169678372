import React from 'react'
import styles from './UnauthorizedPage.module.css'
import { Button } from '@stats/playbook-components'
import { useAuth0 } from '@auth0/auth0-react';

const UnauthorizedPage: React.FC = () => {
  const { logout } = useAuth0()

  return (
    <div className={styles.unauthorizedPageWrapper}>
      <div className={styles.gradientAndLogo}>
        <img
          className={styles.logo}
          src="static/media/Stats_Perform_Logo_white.e4abddf9.svg"
          alt={'Stats Perform logo'}
          height={'58px'}
        />
      </div>
      <span className={styles.mainText}>
        Unauthorized
      </span>
      <span className={styles.secondaryText}>
        Not Authorized to Use Application
      </span>
      <Button
        className={styles.button}
        variant={'primary'}
        onClick={() => logout({ logoutParams: { returnTo: window.location.origin + "/logout" }})}
      >
        Logout
      </Button>
    </div>
  )
}

export default UnauthorizedPage