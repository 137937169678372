import React, { useCallback } from 'react';
import { palette } from '@stats/playbook-components';
import styled from 'styled-components';
import { useAuth0 } from '@auth0/auth0-react';
import LogoutImage from '../Footer/logout.png';

const StyledFooter = styled.footer`
  background-color: ${palette['sp-black']};
  padding: 24px 32px;
  color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const StyledCopyright = styled.small`
  font-family: Roboto, sans-serif;
  font-size: 14px;
`;

const StyledUsername = styled.small`
  font-size: 14px;
  font-weight: bold;  
`;

const StyledLogoutImage = styled.img`
  width: 20px;
  height: 20px;
  cursor: pointer;
  margin-left: 10px;  
`;

const StyledDiv = styled.div`
  display: flex;
`;

export const AppFooter: React.FC = () => {
  const { user, logout } = useAuth0();

  const handleLogout = useCallback(() => {
    logout({ logoutParams: { returnTo: window.location.origin + "/logout" }});
  }, []);

  return (
    <StyledFooter>
      <StyledCopyright>
        &copy; {new Date().getFullYear()} Stats Perform. All rights reserved.
      </StyledCopyright>
      <StyledDiv>
        <StyledUsername>{user && user.email}</StyledUsername>      
        <StyledLogoutImage role="img" src={LogoutImage} alt="Logout" onClick={handleLogout}/>
      </StyledDiv>
    </StyledFooter>
  );
};
