import React from 'react';
import { palette } from '@stats/playbook-components';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import logo from './logo.png';

const StyledHeader = styled.header`
  font-family: Roboto, sans-serif;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-item: flex-end;
  /* Pure black not in palette colors */
  background-color: #000;
  padding: 12px 32px;
  color: ${palette['sp-white']};
  position: sticky;
  top: 0;
  z-index: 10;
`;

const StyledH1 = styled.h1`
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 5.33px;
  vertical-align: baseline;
  margin: 0;
`;

const StyledLogo = styled.img`
  width: 122px;
  height: 44px;
  margin-bottom: -5px;
  margin-right: 17px;
`;

const StyledNav = styled.nav`
  display: flex;
  align-self: flex-end;
`;

const StyledNavLink = styled(NavLink)`
  font-family: Roboto, sans-serif;
  font-size: 16px;
  font-weight: bold;
  line-height: 1.33;
  padding: 0 8px;
  position: relative;
  display: inline-block;
  color: ${palette['sp-white']};
  text-decoration: none;

  & + a {
    margin-left: 16px;
  }

  &.active:after {
    content: '';
    display: block;
    position: absolute;
    background-image: linear-gradient(
      88deg,
      ${palette['kobe']} 0%,
      ${palette['jordan']} 36%,
      ${palette['ripkin']} 67%,
      ${palette['pele']} 100%
    );
    height: 4px;
    width: 100%;
    top: 100%;
    left: 0;
    margin-top: 8px;
  }
`;

export const AppHeader: React.FC = () => {
  return (
    <StyledHeader>
      <StyledH1>
      <StyledLogo role="img" src={logo} alt="logo"/>
        Distribution Services: Admin Tool
      </StyledH1>
      <StyledNav>
        <StyledNavLink to="/clients/overview">Clients</StyledNavLink>
        <StyledNavLink to="/deliveries/overview">Deliveries</StyledNavLink>
        <StyledNavLink to="/files/overview">Files</StyledNavLink>
        <StyledNavLink to="/changehistory/overview">Change History</StyledNavLink>
        <StyledNavLink to="/deliveryhistory/overview">Delivery History</StyledNavLink>
        <StyledNavLink to="/fileretrigger/overview">File Retrigger</StyledNavLink>
        <StyledNavLink to="/deliveryhistory/export">Logs</StyledNavLink>
      </StyledNav>
    </StyledHeader>
  );
};
