import React, { useState, useCallback, useEffect } from 'react';
import styled from 'styled-components';
import { HeaderDiv } from './ClientSummaryDeliveryConfiguration/ConfigStyled';

import Table from '@components/Table';
import Loader from '@components/Loader';
import DateFilter from '@components/DateFilter';
import { Tile } from '@components/Tile';
import { apiURL, useToken } from '@/helpers/apiURL';
import { parseFileSize } from '@/helpers/parsers';
import { getTimezoneOffset } from 'date-fns-tz';

// Negative value in ms
const chicagoTimeOffset = getTimezoneOffset('America/Chicago');

const Filters = styled.div`
  .filter {
    display: inline-block;
    margin-bottom: 20px;
    input {
      width: 260px;
      height: 32px;
      background-color: rgba(242, 242, 242, 1);
      margin-right: 24px;
      box-sizing: border-box;
      border-radius: 2px;
      border: solid 1px rgba(226, 226, 226, 1);
    }
  }
`;

const TableWrap = styled.div`
  margin-top: 40px;
`;

type LogExportData = {
  Items: [];
  TotalPages?: number;
  error?: string;
}

const OverviewLogs: React.FC<{}> = () => {
  const [exportData, setExportData] = useState<LogExportData>({
    Items: [],
    TotalPages: 1
  });
  const [from, setFrom] = useState((new Date(Date.now() + chicagoTimeOffset)).setUTCHours(0, 0, 0, 0) - chicagoTimeOffset);
  const [to, setTo] = useState((new Date(Date.now() + chicagoTimeOffset)).getTime() - chicagoTimeOffset);
  const [isLoading, setIsLoading] = useState(false);

  // Parses object returned from API
  const parseLogExport = (json: any, setter: Function) => {
    const data = {
      Items: json.Items,
      PageSize: json.PageSize,
      TotalPages: json.TotalPages,
    };

    setter(data);
  };

  // Retrieves data from API
  const { privateFetch } = useToken();
  const getExportLinks = useCallback(async () => {
    const timeoutId = setTimeout(() => setIsLoading(true), 500);
    const statsUrl = `${apiURL}metrics/export?from=${from}&to=${to}`;

    await privateFetch(statsUrl).then(async (response) => {
      const json = ((await response.json()) as unknown) as LogExportData;
      if (json && !json.error) {
        parseLogExport(json, setExportData);
      } else {
        setExportData({
          Items: []
        });
      }
    });

    setIsLoading(false);
    clearTimeout(timeoutId);
  }, [privateFetch, from, to]);

  useEffect(() => {
    getExportLinks();
  }, [getExportLinks]);

  // Handles changes made to the to/from dates
  const handleDateChange = useCallback((e: any) => {
    setFrom(e.from);

    if (e.to > (new Date()).getTime() - chicagoTimeOffset) {
      setTo((new Date()).getTime() - chicagoTimeOffset);
    }
    else {
      setTo(e.to);
    }
    
    setIsLoading(true);
  }, []);

  const columns = [
    {
      key: 'date',
      label: 'Date',
      render: (row: { date: string, link: string }) => <a href={row.link} download>{row.date}</a>,
    },
    {
      key: 'size',
      label: 'Size',
      render: (row: { size: number }) => parseFileSize(row.size),
    },
  ];

  const rows = exportData?.Items?.map((item: { id: string }) => {
    return {
      data: item
    };
  });

  return (
    <Tile>
      <HeaderDiv>
        <h1>Log Export</h1>
      </HeaderDiv>
      <div>
        <Filters>
          <DateFilter
            fromDateVal={new Date(from)}
            toDateVal={new Date(to)}
            setDateFilter={handleDateChange}
            maxDaysInPast={30}
          />
        </Filters>
        {isLoading
          ? <Loader />
          : <TableWrap>
              <Table columns={columns} data={rows} keyprop="date" />
            </TableWrap>
        } 
      </div>
    </Tile>
  );
};

export default OverviewLogs;
