import React from "react";
import styled from 'styled-components';
import { typography } from '@stats/playbook-components';

const StyledTD = styled.td`
  ${typography};
  font-size: 12px;
  line-height: 1.17;
  padding: 10px 6px;
`;


type TableCelProps = {
  data: any,
  render?: Function,
  colspan?: number
};

const TableCel: React.FC<TableCelProps> = (props) => {
  const { data, render, colspan } = props;

  return (
    <StyledTD colSpan={colspan}>
      {render ? render(data) : data}
    </StyledTD>
  );
};

export default TableCel;
