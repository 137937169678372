import styled from 'styled-components';
import {
  Dropdown,
  Input,
  Icon,
  Modal
} from '@stats/playbook-components';
import DualListSelector from '@components/DualListSelector';
import { PasswordInput } from '@components/Input';

export const FormWrap = styled.div`
  
  min-height: calc(100vh - 340px);

  &:first-child {
    margin-bottom: 20px;
  }

  .note {
    width: 50%;
    line-height: 20px;
  }

  h1 {
    text-transform: uppercase;
  }

  span.Set {
    font-style: italic;
    opacity: 40%;
  }
`;

export const StyledH2 = styled.h2`
  clear: both;
`;

export const StyledDiv = styled.div`
  display: inline-flex;
  overflow: visible;
`;

export const HeaderDiv = styled.div`
  margin-bottom: 20px;

  button:first-child {
    margin-right: 20px;
  }
`;

export const StyledInput = styled(Input)`
  margin-bottom: 16px;
  margin-right: 24px;
  width: 200px;
`;

export const StyledPassword = styled(PasswordInput)`
  margin-bottom: 16px;
  margin-right: 24px;
  width: 200px;
`;

export const LargeDropdown = styled(Dropdown)`
  margin-bottom: 16px;
  margin-right: 24px;
  width: 350px;
`;

export const SmallInput = styled(StyledInput)`
  width: 200px;
`;

export const SmallDropdown = styled(Dropdown)`
  margin-bottom: 16px;
  margin-right: 24px;
  width: 200px;

  > div:last-child {
    z-index: 1;
  }
`;

export const ControlWrap = styled.div`
  float: right;
`;

export const BatchInput = styled(Input)`
  margin-top: 20px;
  width: 280px;
`;

export const TableInlineInput = styled(Input)`
  font-size: 14px;
  label {display: none;}
  input {
    top: -13px;
    position: absolute;
    width: 100% !important;
    background-color: white !important;
    height: 25px !important;
  }
`;

export const TableInlineDropdown = styled(Dropdown)`
  font-size: 14px;
  width: 80%
`;

export const BatchInputWrap = styled.div`
  .input-wrapper {
    display: inline-block;
    vertical-align: middle;
  }

  button {
    margin-left: 10px;
    margin-top: 10px;
    margin-right: 10px;
    vertical-align: middle;
    display: inline-block;
  }
`;

export const IconPointer = styled(Icon)`
  cursor: pointer;
  float: right;
  opacity: 0.25;

  &:hover {
    opacity: 1;
  }
`;

export const InputIconWrap = styled.div`
  position: relative;

  button {
    position: absolute;
    top: -9px;
    display: inline;
    border: none;
    background-color: transparent !important;
  }

  button:nth-child(2) {
    right: 20px;
  }

  button:nth-child(3) {
    right: 0;
  }
`;

export const DropdownInputIconWrap = styled.div`
  position: relative;

  button {
    position: absolute;
    top: -9px;
    display: inline;
    border: none;
    background-color: transparent !important;
  }

  button:nth-child(2) {
    right: 12px;
    top: 11px;
  }

  button:nth-child(3) {
    right: -7px;
    top: 10px;
  }
`;

export const InputIcon = styled(Icon)`
cursor: pointer;
`;

export const TableWrapper = styled.div`
  table {
    thead {
      tr {
        th:nth-child(1) {
          width: 32px;
        }
        th:nth-child(2) {
          width: 220px;
        }
        th:nth-child(3) {
          width: 80px;
        }
        th:nth-child(4) {
          width: 80px;
        }
        th:nth-child(5) {
          width: 200px;
        }
        th:last-child {
          width: 200px;
        }
      }
    }
  }
`;

export const StyledModal = styled(Modal)`
  && {
    width: 600px;
    margin-top: 0px;
    height: auto;
    min-height: 400px;
    border-top: 4px solid red;
  }
`;

export const StyledDualListSelector = styled(DualListSelector)`
  ul {
    max-height: 300px !important;
  }
`;
