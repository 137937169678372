import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';

import { IconWithHover } from '@stats/playbook-components';
import DualListSelectorPane from './DualListSelectorPane';

import { FileProps, DualListSelectorProps } from './DualListSelectorTypes';

const DualListWrapper = styled.div`
  display: flex;

  & .selector-panel {
    flex-grow: 1;
    flex-shrink: 0;
    flex-basis: 0;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 16px;
  justify-content: center;

  & button + button {
    margin-top: 8px;
  }
`;

const IconWrapper = styled.span`
  display: block;
  margin: 8px 0;
`;

const filterOptionsByKey = (
  key: keyof FileProps,
  available: Array<FileProps>,
  chosen: Array<FileProps>
) => {
  const chosenKeyArray = chosen.map((item) => item[key]);
  return available.filter((option) => !chosenKeyArray.includes(option[key]));
};

const DualListSelector: React.FC<DualListSelectorProps> = (props) => {
  const [availableOptions, setAvailableOptions] = useState(
    props.availableOptions || [] as Array<FileProps>
  );
  const [chosenOptions, setChosenOptions] = useState(props.chosenOptions || [] as Array<FileProps>);
  const [chosenOptionsSelected, setChosenOptionsSelected] = useState(
    [] as (string | number)[]
  );
  const [availableOptionsSelected, setAvailableOptionsSelected] = useState(
    [] as (string | number)[]
  );

  // Add Selected Options
  const addSelected = useCallback(() => {
    const newAvailable = [] as Array<FileProps>;
    const itemsToRemove = [] as Array<FileProps>;
    availableOptions.forEach((option, ind) => {
      if (availableOptionsSelected.includes(ind)) {
        itemsToRemove.push(option);
      } else {
        newAvailable.push(option);
      }
    });

    const newChosen = [...chosenOptions, ...itemsToRemove];

    setAvailableOptionsSelected([]);
    setChosenOptionsSelected([]);
    setAvailableOptions(newAvailable);
    setChosenOptions(newChosen);

    props.onListChange && props.onListChange(newAvailable, newChosen);
  }, [availableOptions, availableOptionsSelected, chosenOptions, props.onListChange]);

  // Remove Selected Options
  const removeSelected = useCallback(() => {
    const newChosen = [] as Array<FileProps>;
    const itemsToRemove = [] as Array<FileProps>;
    chosenOptions.forEach((option, ind) => {
      if (chosenOptionsSelected.includes(ind)) {
        itemsToRemove.push(option);
      } else {
        newChosen.push(option);
      }
    });

    const newAvailable = filterOptionsByKey(
      'id',
      availableOptions || [],
      newChosen
    );

    setAvailableOptionsSelected([]);
    setChosenOptionsSelected([]);
    setAvailableOptions(newAvailable);
    setChosenOptions(newChosen);

    props.onListChange && props.onListChange(newAvailable, newChosen);
  }, [chosenOptions, chosenOptionsSelected, availableOptions, props.onListChange]);

  const onSelectAllToggle = useCallback((
    e: React.ChangeEvent<HTMLInputElement>,
    isChosen: boolean
  ) => {
    if (e.currentTarget.checked) {
      if (isChosen) {
        setChosenOptionsSelected(chosenOptions.map((item, ind) => ind));
      } else {
        setAvailableOptionsSelected(availableOptions.map((item, ind) => ind));
      }
    } else {
      if (isChosen) {
        setChosenOptionsSelected([]);
      } else {
        setAvailableOptionsSelected([]);
      }
    }
  }, [chosenOptions, availableOptions]);

  const onOptionSelect = useCallback((
    _e: React.MouseEvent | React.ChangeEvent,
    index: number,
    isChosen: boolean
  ) => {
    let chosenOptionsSelectedNew = [...chosenOptionsSelected];
    let availableOptionsSelectedNew = [...availableOptionsSelected];

    if (isChosen) {
      const chosenOptionIndex: number = chosenOptionsSelectedNew.indexOf(index);
      if (chosenOptionIndex !== -1) {
        chosenOptionsSelectedNew.splice(chosenOptionIndex, 1);
      } else {
        chosenOptionsSelectedNew.push(index);
      }
      setChosenOptionsSelected(chosenOptionsSelectedNew);
    } else {
      const availableOptionIndex: number =
        availableOptionsSelectedNew.indexOf(index);
      if (availableOptionIndex !== -1) {
        availableOptionsSelectedNew.splice(availableOptionIndex, 1);
      } else {
        availableOptionsSelectedNew.push(index);
      }
      setAvailableOptionsSelected(availableOptionsSelectedNew);
    }
  }, [chosenOptionsSelected, availableOptionsSelected]);

  useEffect(() => {
    const { stringify } = JSON;
    const availableWithoutChosenProp = filterOptionsByKey(
      'id',
      props.availableOptions || [],
      props.chosenOptions || []
    );
    const filteredAvailableOptions = filterOptionsByKey(
      'id',
      availableWithoutChosenProp,
      chosenOptions
    );
    if (stringify(filteredAvailableOptions) !== stringify(availableOptions)) {
      setAvailableOptions(filteredAvailableOptions);
      setAvailableOptionsSelected([]);
    }
  }, [props.availableOptions]);

  useEffect(() => {
    setChosenOptions(props.chosenOptions || [] as Array<FileProps>);
  }, [props.chosenOptions]);

  return (
    <DualListWrapper>
      <DualListSelectorPane
        search={props.search}
        title={props.availableOptionsTitle}
        onSelectAll={onSelectAllToggle}
        options={availableOptions}
        selectedOptions={availableOptionsSelected}
        onOptionSelect={onOptionSelect}
        isLoading={props.isLoading || false}
      />
      <ButtonWrapper>
        <IconWrapper>
          <IconWithHover
            variant="chevron-right"
            role="chevron-right-icon"
            onClick={addSelected}
            fill={
              availableOptionsSelected.length > 0 ? 'sp-black' : 'sp-mid-gray'
            }
          />
        </IconWrapper>
        <IconWrapper>
          <IconWithHover
            variant="chevron-left"
            role="chevron-left-icon"
            onClick={removeSelected}
            fill={chosenOptionsSelected.length > 0 ? 'sp-black' : 'sp-mid-gray'}
          />
        </IconWrapper>
      </ButtonWrapper>
      <DualListSelectorPane
        isChosen
        search={props.search}
        title={props.chosenOptionsTitle}
        options={chosenOptions}
        selectedOptions={chosenOptionsSelected}
        onOptionSelect={onOptionSelect}
        isLoading={false}
      />
    </DualListWrapper>
  );
};

export default DualListSelector;
