import React, { useCallback, useEffect, useState } from 'react';
import FileFilters from '@components/FileFilters';
import { apiURL, useToken } from '@/helpers/apiURL';
import DualListSelector from '@components/DualListSelector';
import { Button } from '@stats/playbook-components';
import styled from 'styled-components';
import EventBus from '@/helpers/eventBus';

const StyledHeader = styled.header`
  display: flex;
  justify-content: space-between;
  margin: 0 0 24px;
`;

const ButtonWrapper = styled.div`
  & button {
    min-width: 120px;
    margin: 10px 50px 0 0;

    & + button {
      margin-left: 16px;
    }
  }
`;

type DeliveryFile = {
  extension?: string;
  id: string;
  name: string;
  type?: string;
};

type FilesData = Array<DeliveryFile>;

type RetriggerFilesProps = {
  clientId?: string;
  deliveryId?: string;
  deliveryMethod?: string;
};

type SaveFileJsonProps = Array<{
  id: string;
  filename: string;
  league: string;
}>;

const RetriggerFiles: React.FC<RetriggerFilesProps> = (props) => {
  const {
    clientId,
    deliveryId,
    deliveryMethod,
  } = props;

  // States
  const [leagueDirectory, setLeagueDirectory] = useState<string>('AUSFL');
  const [fileType, setFileType] = useState<string>('nonlive');
  const [apiLanguage, setApiLanguage] = useState<string>('English');
  const [chosenFiles, setChosenFiles] = useState<Array<DeliveryFile>>([]);
  const [fileOptions, setFileOptions] = useState<FilesData>([]);
  const [buttonText, setButtonText] = useState('Send');
  const [isLoading, setIsLoading] = useState(false);

  const { privateFetch } = useToken();
  const getFiles = useCallback(async () => {
    const timeoutId = setTimeout(() => setIsLoading(true), 500);
    const url = (clientId && deliveryId)
      ? `${apiURL}retrigger/client/${clientId}/delivery/${deliveryId}?league=${leagueDirectory}&type=${fileType}&language=${apiLanguage}`
      : `${apiURL}retrigger?league=${leagueDirectory}&type=${fileType}`;

    const res = await privateFetch(url);
    const json = await res.json();
    setFileOptions(json);
    
    setIsLoading(false);
    clearTimeout(timeoutId);
    }, [leagueDirectory, fileType, apiLanguage, clientId, deliveryId, privateFetch]
  );

  const saveFiles = useCallback(async () => {
    setButtonText('Sending...');

    let url = `${apiURL}retrigger`;

    if (clientId && deliveryId) {
      url += `/client/${clientId}/delivery/${deliveryId}`;
    }

    let requestBody: SaveFileJsonProps = chosenFiles.map((f: any) => {
      return {
        id: f.id,
        league: f.league,
        filename: f.name
      };
    });

    const settings = {
      method: 'POST',
      body: JSON.stringify(requestBody),
    };

    try {
      await privateFetch(url, settings);
      setChosenFiles([]);
      setLeagueDirectory('AUSFL');
      setButtonText('Send');
    }
    catch (_err: any) {
      EventBus.dispatch('notify', { variant: 'error', message: 'Failed to retrigger file delivery' });
    }
  }, [clientId, deliveryId, chosenFiles]);

  const onListChange = useCallback((_availableOptions, chosenOptions) => {
    setChosenFiles(chosenOptions);
  }, []);

  useEffect(() => {
    getFiles();
  }, [getFiles]);

  return (
    <div>
      <StyledHeader>
      <h1>Retrigger File Delivery</h1>
        <ButtonWrapper>
          <Button 
            onClick={saveFiles}
            disabled={chosenFiles.length === 0}
          >{buttonText}</Button>
        </ButtonWrapper>
      </StyledHeader>
      <FileFilters
        deliveryMethod={deliveryMethod || 'HTTP_GET'}
        setLeagueDirectory={setLeagueDirectory}
        setFileType={setFileType}
        setApiLanguage={setApiLanguage}
      />
      <DualListSelector
        availableOptionsTitle={`${leagueDirectory} / ${fileType}`}
        chosenOptionsTitle="Files to retrigger"
        availableOptions={fileOptions}
        chosenOptions={chosenFiles}
        onListChange={onListChange}
        search={true}
        isLoading={isLoading}
      />
    </div>
  );
};

export default RetriggerFiles;
