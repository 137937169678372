import React, { useCallback } from 'react';
import styled from 'styled-components';
import { Dropdown, Icon, typography } from '@stats/playbook-components';

const StyledWrapper = styled.div`
  ${typography};
  max-width: 350px;
  height: 32px;
  margin: 16px auto 0 auto;
  padding: 6px 16px 16px 16px;
  white-space: nowrap;
  display: flex;

  &.hidePager {
    display: none;
  }

  > div {
    margin: auto;
    width: 175px;

    > div {
      display: inline-block;
      height: 23px;
      vertical-align: top;
      margin: 0;
      padding: 2px 0 6px 0;
      width: auto;
    }
  }
`;

const StyledDropdown = styled(Dropdown)`
  display: inline-block;
  min-width: 30px;
  margin-bottom: 20px;
  overflow: visible;

  .dropdown-field {
    padding: 0;
    min-width: 40px;
    overflow: visible;
    border: none;
    background-color: white;
  }

  div:nth-child(2) {
    width: 120px;
  }

  .menu-list {
    width: 100px;
  }
`

const StyledButton = styled.button`
  border: 0;
  display: inline-block;
  background-color: white;
`;

const StyledDropdownPageSize = styled(Dropdown)`
  display: inline-block;
  padding-left: 5px;
  margin-bottom: 20px;
  overflow: visible;

  .dropdown-field {
    padding: 0;
    min-width: 50px;
    overflow: visible;
    border: none;
    background-color: white;
  }

  div:nth-child(2) {
    width: 120px;
  }

  .menu-list {
    width: 100px;
  }
`;

type PaginationProps = {
  page: number;
  totalPages: number;
  setPage: Function;
  pageSize?: number;
  pageSizeOptions?: number[];
  setPageSize?: Function;
};

const Pagination: React.FC<PaginationProps> = (props) => {
  const { page, totalPages, setPage, pageSize, pageSizeOptions, setPageSize } = props;

  const menuItemsNumbers = []
  let count = 1;
  
  while(count <= totalPages) {
    menuItemsNumbers.push({value: count.toString()});
    count ++;
 }
 
  const hider = totalPages > 1 ? 'showPager':'hidePager'

  const setToFirstPage = useCallback(() => {
    setPage(1);
  }, []);

  const goBackOnePage = useCallback(() => {
    setPage(Number(page) - 1);
  }, [page]);

  const goForwardOnePage = useCallback(() => {
    setPage(Number(page) + 1);
  }, [page]);

  const setToLastPage = useCallback(() => {
    setPage(totalPages);
  }, [totalPages]);

  return (
    <StyledWrapper className={hider}>
      { (pageSize && pageSizeOptions) && (
        <div>
          <div>
            <strong>Page Size: </strong>
            <StyledDropdownPageSize 
              onItemSelect={setPageSize}
              value={pageSize.toString()}
              menuItems={pageSizeOptions.map((n) => { return { value: n.toString() }})} 
              excludeDeselectOption
            />
          </div>
        </div>
      )}
      <div>
      <StyledButton onClick={setToFirstPage}>
        <Icon variant="order-first" />
      </StyledButton>

      {page > 1 && (
        <StyledButton onClick={goBackOnePage}>
          <Icon variant="previous" />
        </StyledButton>
      )}


      <div>
      <StyledDropdown 
        onItemSelect={setPage}
        value={page.toString()}
        menuItems={menuItemsNumbers} 
        excludeDeselectOption
      /> of {totalPages}
      </div>

      {page < totalPages && (
        <StyledButton onClick={goForwardOnePage}>
          <Icon variant="next" />
        </StyledButton>
      )}

      <StyledButton onClick={setToLastPage}>
        <Icon variant="order-last" />
      </StyledButton>
      </div>
    </StyledWrapper>
  );
};

export default Pagination;
