import React, { useState, useEffect, useCallback, useContext } from 'react';
import { useParams, NavLink } from 'react-router-dom';
import { apiURL, useToken } from '@/helpers/apiURL';
import { StyledSubNav } from '@/helpers/styles';
import { Tile } from '@components/Tile';
import Loader from '@components/Loader';
import RetriggerFiles from '@components/RetriggerFiles';

import {
  DeliveryConfig,
  DeliveryFiles,
  DeliveryHistory,
} from './ClientSummaryDeliveryConfiguration';
import DeliveryMethodsContext from '@/DeliveryMethodsContext';

type DeliveryFile = {
  extension: string;
  format: string;
  id: string;
  name: string;
  type: string;
};

type DeliveryData = {
  name: string;
  files: Array<DeliveryFile>;
  deliveryMethod: string;
  clientName: string;
  lastUpdatedAt: string;
  id: string;
  clientId: string;
};

type DeliverySubNavProps = {
  configTitle: string;
  deliveryMethod: string;
};

const DeliverySubNavigation: React.FC<DeliverySubNavProps> = (props) => {
  const params = useParams<{ clientId: string; deliveryId: string }>();
  const { configTitle } = props;
  const { clientId, deliveryId } = params;

  return (
    <StyledSubNav>
      <li>
        <NavLink exact to={`/clients/${clientId}/deliveries/${deliveryId}`}>
          Files
        </NavLink>
      </li>
      <li>
        <NavLink to={`/clients/${clientId}/deliveries/${deliveryId}/config`}>
          {configTitle}
        </NavLink>
      </li>
      <li>
        <NavLink to={`/clients/${clientId}/deliveries/${deliveryId}/history`}>
          Delivery History
        </NavLink>
      </li>
      <li>
        <NavLink to={`/clients/${clientId}/deliveries/${deliveryId}/retrigger`}>
          Retrigger File Delivery
        </NavLink>
      </li>
    </StyledSubNav>
  );
};

type displayPageProps = {
  subtab: string,
  clientId: string,
  deliveryId: string,
  clientDelivery: any,
  getDeliveryDetails: Function
}

const DisplayPage = (props: displayPageProps) => {
  const { subtab, clientId, deliveryId, clientDelivery, getDeliveryDetails } = props;
  if (subtab === 'history') {
    return (
      <DeliveryHistory 
        clientId={clientId}
        deliveryId={deliveryId}
      />
    )
  }
  else if (subtab === 'retrigger') {
    return (
      <RetriggerFiles
        clientId={clientId}
        deliveryId={deliveryId}
        deliveryMethod={clientDelivery.deliveryMethod}
      />
    )
  }
  else if (!subtab) {
    return (
      <DeliveryFiles
        delivery={clientDelivery}
        updateClientDelivery={getDeliveryDetails}
        clientId={clientId}
        deliveryId={deliveryId}
      />
    );
  }
  else {
    return (
      <DeliveryConfig 
        delivery={clientDelivery}
        updateClientDelivery={getDeliveryDetails}
        clientId={clientId}
      />
    );
  }
};

const ClientDeliveryDetail: React.FC<{}> = () => {
  const { privateFetch } = useToken();
  const [deliveryMethodText, setDeliveryMethodText] = useState<string>('');
  const [clientDelivery, setClientDelivery] = useState<DeliveryData>({
    name: '',
    files: [],
    deliveryMethod: '',
    clientName: '',
    lastUpdatedAt: '',
    id: '',
    clientId: ''
  });
  const [isLoading, setIsLoading] = useState(false);

  const params = useParams<{
    clientId: string;
    deliveryId: string;
    subtab: string;
  }>();
  const { clientId, deliveryId, subtab } = params;

  const deliveryMethods = useContext(DeliveryMethodsContext);

  const getDeliveryDetails = useCallback(async () => {
    const timeoutId = setTimeout(() => setIsLoading(true), 500);
    const response = await privateFetch(`${apiURL}clients/${clientId}/deliveries/${deliveryId}`);
    const json = await response.json();

    const { deliveryMethod } = json;
    const methodText: string = deliveryMethods
      .filter(({ method }) => method === deliveryMethod)
      .reduce((str, method) => method.text, '');

    setClientDelivery(json);
    setDeliveryMethodText(methodText);
    setIsLoading(false);
    clearTimeout(timeoutId);
  }, [clientId, deliveryId, privateFetch, apiURL]);

  const Title = clientDelivery.name
    ? [clientDelivery.name, ` (${deliveryMethodText})`].join('')
    : 'Loading...';

  useEffect(() => {
    getDeliveryDetails()
  }, [getDeliveryDetails]);

  

  return (
    <Tile
      title={Title}
      childnav={
        <DeliverySubNavigation
          deliveryMethod={clientDelivery.deliveryMethod}
          configTitle={`${deliveryMethodText} Configuration`}
        />
      }
    >
      { isLoading 
        ? <Loader />
        : <DisplayPage
            subtab={subtab}
            clientId={clientId}
            deliveryId={deliveryId}
            clientDelivery={clientDelivery}
            getDeliveryDetails={getDeliveryDetails} 
          />
      }
    </Tile>
  );
};

export default ClientDeliveryDetail;
