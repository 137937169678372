import React, { useCallback, useState } from 'react';
import { Button, Input } from '@stats/playbook-components';

type FilterProps = {
  filename: string;
  type: string;
  league: string;
  onSubmit: Function;
}

const DeliveryHistoryFilters: React.FC<FilterProps> = (props) => {
  const [fileText, setFileText] = useState(props.filename);
  const [typeText, setTypeText] = useState(props.type);
  const [leagueText, setLeagueText] = useState(props.league);

  const onFilenameChange = useCallback((e: React.FormEvent<HTMLInputElement>) => {
    setFileText(e.currentTarget.value);
  }, []);

  const onFileTypeChange = useCallback((e: React.FormEvent<HTMLInputElement>) => {
    setTypeText(e.currentTarget.value);
  }, []);

  const onLeagueChange = useCallback((e: React.FormEvent<HTMLInputElement>) => {
    setLeagueText(e.currentTarget.value);
  }, []);

  const onClick = useCallback(() => {
    props.onSubmit({
      file: fileText,
      type: typeText,
      league: leagueText
    });
  }, [fileText, typeText, leagueText]);

  return (
    <>
      <Input
        value={fileText}
        label='Filename'
        className={'filter'}
        onChange={onFilenameChange}
      />

      <Input
        value={typeText}
        label='File Type'
        className={'filter'}
        onChange={onFileTypeChange}
      />

      <Input
        value={leagueText}
        label='League'
        className={'filter'}
        onChange={onLeagueChange}
      />

      <Button variant="primary-black" onClick={onClick}>
        Filter
      </Button>
    </>
  );
}

export default DeliveryHistoryFilters;