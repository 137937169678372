import React, { MouseEventHandler, useCallback, useEffect, useState } from 'react';
import FileFilters from '@components/FileFilters';
import { apiURL, useToken } from '@/helpers/apiURL';
import DualListSelector from '@components/DualListSelector';
import { Button, palette } from '@stats/playbook-components';
import styled from 'styled-components';
import dateRender from '@/helpers/dateRender';

const StyledHeader = styled.header`
  display: flex;
  justify-content: space-between;
  margin: 0 0 24px;
`;

const Heading = styled.h2`
  font-size: 14px;
  font-weight: bold;
  margin: 0;
`;

const ButtonWrapper = styled.div`
  & button {
    min-width: 140px;

    & + button {
      margin-left: 16px;
    }
  }
`;

const LastUpdated = styled.p`
  font-size: 10px;
  font-style: italic;
  color: ${palette['sp-dark-gray-25']};
  margin: 0;
`;

type DeliveryFile = {
  extension?: string;
  format?: string;
  id: string;
  name: string;
  type?: string;
};

type FilesData = {
  Items: Array<DeliveryFile>;
};

type AddFilesProps = {
  clientId: string;
  deliveryId: string;
  lastUpdated?: string;
  onCancel: MouseEventHandler<HTMLButtonElement>;
  onSave: Function;
  deliveryMethod: string;
};

type SaveFileObj = {
  id: string;
  apiFormat?: string;
  apiLanguage?: string;
  apiParameters?: string[];
}

type SaveFileJsonProps = {
  files: SaveFileObj[];
  operationType: 'insert';
};

const AddFiles: React.FC<AddFilesProps> = (props) => {
  const {
    clientId,
    deliveryId,
    lastUpdated,
    onSave,
    onCancel,
    deliveryMethod,
  } = props;

  // States
  const [leagueDirectory, setLeagueDirectory] = useState<string>('AUSFL');
  const [fileFormat, setFileFormat] = useState<string>((deliveryMethod === "HTTP_POST") ? 'API Endpoint' : 'XML');
  const [fileType, setFileType] = useState<string>('nonlive');
  const [apiFormat, setApiFormat] = useState<string>('JSON');
  const [apiLanguage, setApiLanguage] = useState<string>('English');
  const [chosenFiles, setChosenFiles] = useState<Array<DeliveryFile>>([]);
  const [fileOptions, setFileOptions] = useState<FilesData>({
    Items: [],
  });
  const { privateFetch } = useToken();
  const getFiles = useCallback(
    () =>
      privateFetch(
        `${apiURL}files?leagueDirectoryName=${leagueDirectory}&format=${fileFormat}&type=${fileType}&size=500`
      ),
    [leagueDirectory, fileFormat, fileType, privateFetch]
  );

  const saveFiles = useCallback(async () => {
    const url = `${apiURL}clients/${clientId}/deliveries/${deliveryId}`;

    let requestBody: SaveFileJsonProps = {
      files: chosenFiles.map((file) => {
        const fileObj: SaveFileObj = {
          id: file.id
        };

        if (deliveryMethod === 'HTTP_POST') {
          fileObj.apiFormat = apiFormat;
          fileObj.apiLanguage = apiLanguage;
          fileObj.apiParameters = [];
        }

        return fileObj;
      }),
      operationType: 'insert',
    };

    const settings = {
      method: 'POST',
      body: JSON.stringify(requestBody),
    };

    await privateFetch(url, settings).then(() => {
      onSave(chosenFiles);
    });
  }, [deliveryId, chosenFiles, deliveryMethod, apiFormat, apiLanguage, privateFetch]);

  const onListChange = useCallback((_availableOptions, chosenOptions) => {
    setChosenFiles(chosenOptions);
  }, []);

  useEffect(() => {
    let isMounted = true;
    getFiles()
      .then((res) => res.json())
      .then((json) => {
        if (isMounted) {
          setFileOptions(json);
        }
      });
    return () => {
      isMounted = false;
    };
  }, [getFiles]);

  return (
    <div>
      <StyledHeader>
        <div>
          <Heading>ADD FILES</Heading>
          {lastUpdated && (
            <LastUpdated>Last updated on {dateRender(lastUpdated)}</LastUpdated>
          )}
        </div>
        <ButtonWrapper>
          <Button onClick={saveFiles} disabled={chosenFiles.length === 0}>Save</Button>
          <Button variant="secondary-light" onClick={onCancel}>
            Cancel
          </Button>
        </ButtonWrapper>
      </StyledHeader>
      <FileFilters
        deliveryMethod={deliveryMethod}
        setLeagueDirectory={setLeagueDirectory}
        setFileFormat={setFileFormat}
        setFileType={setFileType}
        setApiLanguage={setApiLanguage}
        setApiFormat={setApiFormat}
      />
      <DualListSelector
        availableOptionsTitle={`${leagueDirectory} / ${fileFormat} / ${fileType}`}
        chosenOptionsTitle="Added Files"
        availableOptions={fileOptions.Items}
        chosenOptions={chosenFiles}
        onListChange={onListChange}
        search={true}
      />
    </div>
  );
};

export default AddFiles;
