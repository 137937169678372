import React, { useEffect, useState, useCallback, ChangeEvent } from 'react';
import styled from 'styled-components';
import Loader from '@components/Loader';
import Table from '@components/Table';
import { ConfirmSwitch } from '@components/ConfirmSwitch';
import Pagination from '@components/Table/Pagination';
import { Tile } from '@components/Tile';

import SearchBox from '@components/SearchBox';
import DeliveryFilter from '@components/DeliveryFilter';

import { useHistory } from 'react-router-dom';

import { apiURL, useToken } from '@/helpers/apiURL';
import { Icon } from '@stats/playbook-components';

type OverviewDeliveriesProps = {};

type DeliveryData = {
  Items: [];
  CurrentPage: number;
  PageSize: number;
  TotalPages: number;
};

const TableWrapper = styled.div`
  table {
    thead {
      tr {
        th:first-child {
          width: 32px;
        }
        th:nth-child(2) {
          width: 217px;
        }
        th:nth-child(3) {
          width: 160px;
        }
        th:nth-child(4) {
          width: 136px;
        }
        th:nth-child(5) {
          width: 160px;
        }
        th:nth-child(6) {
          width: 160px;
        }
        th:nth-child(7) {
          width: 192px;
        }
        th:nth-child(8) {
          width: 80px;
        }
        th:last-child {
          width: 32px;
        }
      }
    }
    td:last-child {
      text-align: right;
      svg {
        margin-left: 2px;
        margin-right: 14px;
      }

      &:nth-child(2) {
        text-align: left;
      }
    }
  }
`;

const IconPointer = styled(Icon)`
  cursor: pointer;
`;

const OverviewDeliveries: React.FC<OverviewDeliveriesProps> = () => {
  const [deliveries, setDeliveries] = useState<DeliveryData>({
    Items: [],
    CurrentPage: 1,
    PageSize: 20,
    TotalPages: 1,
  });
  const history = useHistory();

  const { privateFetch } = useToken();

  const [page, setPage] = useState(1);
  const [search, setSearch] = useState('');
  const [deliveryMethod, setDeliveryMethod] = useState('');
  const [status, setStatus] = useState('');
  const [isLoading, setIsLoading] = useState(true);

  const setEnabledFilter = useCallback((value: string) => {
    return setStatus(value);
  }, []);
  const setMethodFilter = useCallback((value: string) => {
    setDeliveryMethod(value);
  }, []);

  const getDeliveries = useCallback(async () => {
    const timeoutId = setTimeout(() => setIsLoading(true), 500);
    const size = 20;
    let url = `${apiURL}deliveries/?page=${page}&size=${size}&name=${search}`;
    url =
      deliveryMethod !== '' && deliveryMethod !== 'Any'
        ? `${url}&deliveryMethod=${deliveryMethod}`
        : url;

    url = status !== "" ? `${url}&status=${status}` : url;

    const response = await privateFetch(url)
    const json = await response.json() as DeliveryData;
    if (json) {
      setDeliveries(json);
    }

    setIsLoading(false);
    clearTimeout(timeoutId);
  }, [page, search, status, deliveryMethod, privateFetch]);

  useEffect(() => {
    getDeliveries();
  }, [getDeliveries]);

  const tableColumns = [
    {
      key: 'name',
      label: 'Name',
      render: (row: { name: string }) => row.name,
    },
    {
      key: 'deliveryMethod',
      label: 'Method',
      render: (row: { deliveryMethod: string }) => row.deliveryMethod,
    },
    {
      key: 'clientName',
      label: 'Client',
      render: (row: { clientName: string }) => row.clientName,
    },
    {
      key: 'filesCount',
      label: 'Files',
      render: (row: { filesCount: string }) => row.filesCount,
    },
    {
      key: 'enabled',
      label: 'Enabled',
      render: (row: { enabled: typeof ConfirmSwitch }) => row.enabled,
    },
    {
      key: 'detaillink',
      label: '',
      render: (row: { id: string; clientId: string }) => {
        return (
          <IconPointer
            size="medium"
            variant="edit"
            onClick={() => history.push(`/clients/${row.clientId}/deliveries/${row.id}`)}
            role="edit-icon"
          />
        );
      }
    },
  ];

  const enableToggle = useCallback(async (
    value: {
      current: { querySelector: Function } | null;
    },
    item: { clientId: string; id: string }
  ) => {
    const enabled = value.current?.querySelector('input').defaultChecked;

    const url = `${apiURL}clients/${item.clientId}/deliveries/${item.id}`;

    try {
      const settings = {
        method: 'PUT',
        body: JSON.stringify({ status: enabled ? 0 : 1 }),
      };

      const deliveryStatus = await privateFetch(url, settings);
      const data = await deliveryStatus.json();
      return data;
    } catch (e) {
      return e;
    }
  }, [apiURL, privateFetch]);

  const tableData = deliveries?.Items.map(
    (item: {
      status: number;
      clientId: string;
      id: string;
      filesCount: number;
    }) => {
      const checkboxRef = React.createRef<HTMLElement>();
      const revised = {
        ...item,
        clientDeliveryKey: `CLIENT${item.clientId}_${item.id}`,
        enabled: (
          <span ref={checkboxRef}>
            <ConfirmSwitch
              defaultChecked={item.status === 1}
              onClick={() => {
                enableToggle(checkboxRef, item);
              }}
              modalTitle="Disable Delivery"
              modalText="Are you sure you want to disable this delivery? The
              client will no longer receive files defined in this delivery."
              modalConfirmText="Disable"
            />
          </span>
        ),
      };

      return {
        data: revised,
        subdata:
          item.filesCount > 0 &&
          (() =>
            privateFetch(
              `${apiURL}clients/${item.clientId}/deliveries/${item.id}`
            ).then(async (response) => {
              const json = ((await response.json()) as unknown) as any;
              if (json) {
                // Set the unique key on each file
                json.files.forEach((f: any) => f.tableKey = `${f.id}_${f.sk}`);
                return json.files;
              }
            })),
      };
    }
  );

  const handleSearchChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setSearch(e.currentTarget.value);
    if (page > 1) setPage(1);
  }, [page]);

  const subdataRender = useCallback((row: any) => `${row.name} (${row.type})`, []);

  return (
    <Tile title="Deliveries">
      <SearchBox
        onChange={handleSearchChange}
        placeholder="Search Deliveries"
      />
      <DeliveryFilter
        setPage={setPage}
        setEnabledFilter={setEnabledFilter}
        setMethodFilter={setMethodFilter}
      />
      { isLoading 
        ? <Loader />
        : <>
            <TableWrapper>
              <Table
                columns={tableColumns}
                data={tableData}
                keyprop="clientDeliveryKey"
                subdataKey="tableKey"
                subdataRender={subdataRender}
              />
            </TableWrapper>
            {deliveries?.TotalPages > 0 && (
              <Pagination
                page={page}
                totalPages={deliveries?.TotalPages}
                setPage={setPage}
              />
            )}
          </>
      }
    </Tile>
  );
};

export default OverviewDeliveries;
