import React from 'react';
import { HeaderDiv } from './ConfigStyled';

import { DeliveryHistoryList } from '@components/DeliveryHistoryList';

type DeliveryHistoryProps = {
  clientId: string;
  deliveryId: string
};

const DeliveryHistory: React.FC<DeliveryHistoryProps> = (props) => {
  const { deliveryId, clientId } = props;

  return (
    <>
      <HeaderDiv>
        <h1>Delivery History</h1>
      </HeaderDiv>
      <DeliveryHistoryList
        deliveryId={deliveryId}
        clientId={clientId} 
      />
    </>
  );
};

export default DeliveryHistory;
