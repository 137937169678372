import React from 'react';

import { Tile } from '@components/Tile';
import RetriggerFiles from '@components/RetriggerFiles';

const FileRetrigger: React.FC<{}> = (props) => {

  return (
    <Tile>
      <RetriggerFiles />
    </Tile>
  );
};

export default FileRetrigger;
