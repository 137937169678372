import React, { useCallback } from 'react';
import styled from 'styled-components';

import Checkbox from '@components/Checkbox';
import { DualListSelectorItemProps } from './DualListSelectorTypes';

const StyledLI = styled.li`
  padding: 8px;
`;

const DualListSelectorItem: React.FC<DualListSelectorItemProps> = (props) => {
  const { label, orderIndex, isSelected, isChosen, onOptionSelect } = props;

  const handleChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    onOptionSelect(e, orderIndex, isChosen);
  }, [onOptionSelect, orderIndex, isChosen]);

  return (
    <StyledLI key={orderIndex}>
      <Checkbox
        label={label}
        onChange={handleChange}
        checked={isSelected}
      />
    </StyledLI>
  );
};

export default DualListSelectorItem;
