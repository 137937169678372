import React, { useCallback, useState, useEffect } from 'react';
import styled from 'styled-components';
import { 
  Icon,
  IconWithHover,
  palette,
  typography 
} from '@stats/playbook-components';
import classNames from 'classnames';
import { IconVariants } from './icon-data';
import EventBus from '@/helpers/eventBus';

const StyledWrapper = styled.div`
  ${typography};
  height: 40px;
  padding: 0 12px 0 16px;
  display: flex;
  align-items: center;
  opacity: 1;
  position: absolute;
  z-index: 9999;
  justify-content: center;
  top: 105px;
  left: 45%;
  font-size: 18px;
  font-weight: bold;

  background-color: ${palette['tendulkar-op-24']};
  .message {
    flex: 1 1;
    padding-left: 8px;
    padding-right: 8px;
    line-height: 1.71;
  }
  &.success {
    background-color: ${palette['serena-op-24']};
  }
  &.warning {
    background-color: ${palette['pele-op-24']};
  }
  &.error {
    background-color: ${palette['jordan-op-24']};
  }

  &.closed {
    opacity: 0;
    transition: opacity 1s ease-out;
  }
`;

type NotificationEvent = {
  variant: string;
  message: string;
};

const variantIcons: {
  [k: string]: { variant: IconVariants; fill: keyof typeof palette };
} = {
  success: { variant: 'check', fill: 'serena' },
  warning: { variant: 'warning', fill: 'pele' },
  error: { variant: 'error', fill: 'jordan' },
  information: { variant: 'info', fill: 'tendulkar' },
};

export const Notification: React.FC<{}> = () => {
  const [status, changeStatus] = useState<string>('closed');
  const [variant, setVariant] = useState('success');
  const [message, setMessage] = useState('');

  useEffect(() => {
    EventBus.on("notify", (data: NotificationEvent) => {
      setVariant(data.variant);
      setMessage(data.message);
      changeStatus('open');
      EventBus.remove('notify', () => {});
    });
  });

  const handleClick = useCallback(() => {
    changeStatus('closing');
    setTimeout(() => changeStatus('closed'), 1000);
  }, []);

  const notificationClassName = classNames(variant, {
    closed: status !== 'open',
  });

  return (
    <>
      { (status === 'open' || status === 'closing') &&
        <StyledWrapper className={notificationClassName}>
          <Icon
            variant={variantIcons[variant].variant}
            fill={variantIcons[variant].fill}
            size={'medium'}
            role="img"
          />
          <div className={'message'}>
            <p>{message}</p>
          </div>
          <IconWithHover
            variant={'x'} 
            size={'medium'} 
            onClick={handleClick}
            role="img"
          />
        </StyledWrapper>
      }   
    </>
  );
};
