import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import Table from '@components/Table';
import { Icon, Button } from '@stats/playbook-components';
import { ConfirmSwitch } from '@components/ConfirmSwitch';
import { apiURL, useToken } from '@/helpers/apiURL';
import { DeleteDeliveryModal, AddDeliveryModal} from '@components/Modals';

const LastUpdated = styled.p`
  float: left;
  font-size: 10px;
  font-style: italic;
  color: #6f6f6f;
  margin-bottom: 20px;s
  margin-top: 0;
`;

const ButtonWrap = styled.div`
  display: inline-block;
  width: 49%;
  text-align: right;
`;

const TableWrapper = styled.div`
  table {
    thead {
      tr {
        th:nth-child(1) {
          width: 380px;
        }
        th:nth-child(2) {
          width: 380px;
        }
        th:nth-child(3) {
          width: 50px;
        }
        th:last-child {
          width: 120px;
        }
      }
    }
  }
  td:last-child {
    text-align: right;
    white-space: nowrap;
      svg {
        margin-left: 2px;
        margin-right: 14px;
      }
  }
`;

const IconPointer = styled(Icon)`
  cursor: pointer;
`;

type ClientDeliveriesProps = {
  deliveryItems: number;
  enabledDeliveryItems: number;
  createdAt: string;
  lastUpdatedAt: string;
  id: string;
  name: string;
  deliveries: any[];
  refreshClient: Function;
};

const ClientDeliveries: React.FC<ClientDeliveriesProps> = (props) => {
  const { deliveries, id, lastUpdatedAt, refreshClient } = props;

  const [openAddDeliveryModal, setOpenAddDeliveryModal] = useState<boolean>(
    false
  );
  const [openCloneDeliveryModal, setOpenCloneDeliveryModal] = useState<any>(
    null
  );
  const [
    openDeleteDeliveryModal,
    setOpenDeleteDeliveryModal,
  ] = useState<string>('');

  useEffect(() => {
    if (
      !openAddDeliveryModal &&
      !openCloneDeliveryModal &&
      !openDeleteDeliveryModal
    ) {
      refreshClient(true);
    }
  }, [
    openAddDeliveryModal,
    openCloneDeliveryModal,
    openDeleteDeliveryModal,
  ]);

  const { privateFetch } = useToken();

  const enableToggle = async (
    value: {
      current: { querySelector: Function } | null;
    },
    item: { clientId: string; id: string }
  ) => {
    const enabled = value.current?.querySelector('input').defaultChecked;

    const url = `${apiURL}clients/${item.clientId}/deliveries/${item.id}`;

    try {
      const settings = {
        method: 'PUT',
        body: JSON.stringify({ status: enabled ? 0 : 1 }),
      };

      const deliveryStatus = await privateFetch(url, settings);
      const data = await deliveryStatus.json();
      return data;
    } catch (e) {
      return e;
    }
  };

  const columns = [
    {
      key: 'name',
      label: 'Name',
      render: (row: { name: string }) => row.name,
      sortable: true,
    },
    {
      key: 'deliveryMethod',
      label: 'Delivery Method',
      render: (row: { deliveryMethod: string }) => row.deliveryMethod,
      sortable: true,
    },

    {
      key: 'enabled',
      label: 'Enabled',
      render: (row: {
        status: number;
        clientId: string;
        id: string;
        checkboxRef: any;
      }) => (
        <span ref={row.checkboxRef}>
          <ConfirmSwitch
            defaultChecked={row.status === 1}
            onClick={() => {
              enableToggle(row.checkboxRef, row).then(() => {
                refreshClient(true);
              });
            }}
            modalTitle="Disable Delivery"
            modalText="Are you sure you want to disable this delivery? The
            client will no longer receive files defined in this delivery."
            modalConfirmText="Disable"
          />
        </span>
      ),
    },
    {
      key: 'controls',
      label: '',
      render: (row: { id: string; name: string; deliveryMethod: string }) => (
        <>
          <IconPointer
            size="medium"
            variant="copy"
            role="copy-icon"
            onClick={() =>
              setOpenCloneDeliveryModal({
                name: row.name + '_copy',
                deliveryMethod: row.deliveryMethod,
                id: row.id,
              })
            }
          />
          <IconPointer
            size="medium"
            variant="delete"
            role="delete-icon"
            onClick={() => setOpenDeleteDeliveryModal(row.id)}
          />
        </>
      ),
    },
  ];

  const tableData = deliveries.map((delivery: Object) => {
    const checkboxRef = React.createRef<HTMLSpanElement>();
    const revised = { ...delivery, checkboxRef: checkboxRef, clientId: id };

    return {
      data: revised,
    };
  });

  const dateRender = (datetime: string) => {
    const format = new Intl.DateTimeFormat('en-US', {
      month: 'long',
      day: 'numeric',
      year: 'numeric',
    });

    return format.format(new Date(datetime));
  };

  const handleCloseAddDeliveryModal = useCallback(() => {
    setOpenAddDeliveryModal(false);
  }, []);

  const handleCloseCloneDeliveryModal = useCallback(() => {
    setOpenCloneDeliveryModal(null);
  }, []);

  const handleCloseDeleteDeliveryModal = useCallback(() => {
    setOpenDeleteDeliveryModal('');
  }, []);

  return (
    <>
      <h1>Deliveries</h1>
      <ButtonWrap>
        <Button onClick={() => setOpenAddDeliveryModal(true)} variant="primary">
          Add Delivery
        </Button>
      </ButtonWrap>
      <LastUpdated>
        Last updated on {lastUpdatedAt && dateRender(lastUpdatedAt)}
      </LastUpdated>
      <TableWrapper>
        <Table columns={columns} data={tableData} keyprop="id" />
      </TableWrapper>

      {openAddDeliveryModal && (
        <AddDeliveryModal
          handleClose={handleCloseAddDeliveryModal}
          delivery={{
            name: '',
            deliveryMethod: '',
          }}
          clientId={id}
        />
      )}

      {!!openCloneDeliveryModal && (
        <AddDeliveryModal
          handleClose={handleCloseCloneDeliveryModal}
          delivery={openCloneDeliveryModal}
          clientId={id}
          clone
        />
      )}

      {!!openDeleteDeliveryModal && (
        <DeleteDeliveryModal
          handleClose={handleCloseDeleteDeliveryModal}
          deliveryId={openDeleteDeliveryModal}
          clientId={id}
        />
      )}
    </>
  );
};

export default ClientDeliveries;
