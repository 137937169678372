import React from 'react';
import styled from 'styled-components';
import { Label } from '@/components/Label';
import {
  palette,
  ThemeType,
  Icon,
  typography,
} from '@stats/playbook-components';

import classNames from 'classnames';

const StyledWrapper = styled.div`
  ${typography};
  background-color: transparent;
  display: flex;
  flex-direction: column;

  textarea {
    background-color: ${palette['sp-light-gray-25']};
  }

  &.dark {
    color: ${palette['sp-white']};
    textarea {
      background-color: ${palette['sp-black']};
    }
  }

  .inner-wrapper {
    position: relative;

    .state-icon {
      position: absolute;
      right: -8px;
      top: 8px;
    }
  }

  &.slim .inner-wrapper .state-icon {
    top: 4px;
  }

  &.side-label {
    flex-direction: row;

    label {
      height: 40px;
      line-height: 44px;
      width: 96px;
      font-size: 14px;
      padding-right: 16px;
      text-align: right;
    }
  }

  &.side-label.slim {
    label {
      height: 32px;
      line-height: 36px;
    }
  }

  .helper-text {
    color: ${palette['sp-dark-gray-25']};
    font-size: 10px;
    line-height: 1.6;
    padding: 4px 0;
  }
`;

type TextAreaHTMLAttributes = React.HTMLAttributes<HTMLTextAreaElement>;
type StyledTextAreaType = {};
type StyledTextAreaProps = StyledTextAreaType & TextAreaHTMLAttributes;

const StyledTextArea = styled.textarea<StyledTextAreaType>`
  height: 40px;
  width: 100%;
  padding: 0 0 0 16px;

  color: inherit;
  background-color: inherit;
  border-radius: 2px;
  border: solid 1px ${palette['sp-light-gray-50']};

  .slim & {
    height: 32px;
  }

  &.success {
    border: solid 1px ${palette['serena']};
  }

  &.error {
    border: solid 1px ${palette['jordan']};
  }

  &.disabled {
    color: ${palette['sp-mid-gray']};
  }
`;

type TextAreaStateType = 'success' | 'error';
type LabelPositionType = 'top' | 'side';
type SizeType = 'normal' | 'slim';

type TextAreaType = {
  id?: string;
  label: string;
  required?: boolean;
  value?: string;
  state?: TextAreaStateType;
  disabled?: boolean;
  theme?: ThemeType;
  size?: SizeType;
  labelPosition?: LabelPositionType;
  helperText?: string;
};
export type TextAreaProps = TextAreaType & StyledTextAreaProps;

export const TextArea: React.FC<TextAreaProps> = (props) => {
  const {
    value,
    state,
    theme,
    size,
    labelPosition,
    helperText,
    className,
    ...rest
  } = props;
  const textareaClass = classNames(state, {
    disabled: props.disabled,
  });
  const wrapperClass = classNames('textarea-wrapper', className, {
    'side-label': labelPosition === 'side',
    dark: theme === 'dark',
    slim: size === 'slim',
  });
  return (
    <StyledWrapper className={wrapperClass}>
      <Label
        label={props.label}
        htmlFor={props.id}
        required={props.required}
        disabled={props.disabled}
      />
      <div className={'inner-wrapper'}>
        <StyledTextArea
          className={textareaClass}
          defaultValue={value}
          {...rest}
        />
        {state === 'success' && (
          <Icon className={'state-icon'} variant={'check'} fill={'serena'} />
        )}
        {state === 'error' && (
          <Icon className={'state-icon'} variant={'error'} fill={'jordan'} />
        )}
        {helperText && <div className={'helper-text'}>{helperText}</div>}
      </div>
    </StyledWrapper>
  );
};
