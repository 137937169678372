import React, { useCallback, useContext, useState } from 'react';
import {
  DropdownInputIconWrap,
  FormWrap,
  HeaderDiv,
  BatchInput,
  BatchInputWrap,
  IconPointer,
  InputIcon,
  InputIconWrap,
  StyledDualListSelector,
  StyledModal,
  TableInlineDropdown,
  TableInlineInput,
  TableWrapper,
} from './ConfigStyled';
import {
  LastUpdated,
  ButtonWrap,
  DeleteButtonWrap,
  CheckBoxWrap,
} from '@/helpers/styles';
import { Button } from '@stats/playbook-components';
import dateRender from '@/helpers/dateRender';
import { apiURL, useToken } from '@/helpers/apiURL';
import AddFiles from '@components/AddFiles';
import Checkbox from '@components/Checkbox';
import Table from '@components/Table';
import FileFiltersContext from '@/FileFiltersContext';

type RowProps = {
  text: string;
  sk: string;
  updateUrl: string;
  handleRename: Function
};

const RowInput: React.FC<RowProps> = (props) => {
  const { text, sk, updateUrl, handleRename } = props;
  const [value, setValue] = useState(text);
  const [showInput, setShowInput] = useState(false);

  const { privateFetch } = useToken();

  const updateRename = useCallback(async () => {
    try {
      const requestBody = {
        sk: sk,
        renamed: value.trim(),
      };

      const settings = {
        method: 'PUT',
        body: JSON.stringify(requestBody),
      };

      await privateFetch(updateUrl, settings);
      await handleRename();
    } catch (e) {
      console.log(e);
      return e;
    }
  }, [value, sk, privateFetch, updateUrl, handleRename]);

  const handleInputChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.currentTarget.value);
  }, []);

  const changeShowInput = useCallback(() => {
    setShowInput(!showInput);
  }, [showInput]);

  return (
    <>
      {!showInput
        ? <span>
            <span className={text}>{text}</span>
            <IconPointer
              role="edit-icon"
              size="small"
              variant="edit"
              onClick={changeShowInput}
            />
          </span>
        : <InputIconWrap>
            <TableInlineInput
              onChange={handleInputChange}
              label=""
            />
            <button onClick={updateRename}>
              <InputIcon role="check-icon" fill="serena" size="small" variant="check" />
            </button>
            <button
              onClick={changeShowInput}
            >
              <InputIcon role="cancel-icon" fill="jordan" size="small" variant="x" />
            </button>
          </InputIconWrap>
      }
    </>
  );
};

type ApiLanguageRowProps = {
  text: string;
  id: string;
  update: Function;
  sk: string;
};

const ApiLanguageRowInput: React.FC<ApiLanguageRowProps> = (props) => {
  const { text, id, update, sk } = props;
  const [value, setValue] = useState(text);
  const [showInput, setShowInput] = useState(false);

  const fileFilters = useContext(FileFiltersContext);
  const languages = fileFilters.languages.map((str: string) => ({ value: str }));

  const handleSave = useCallback(async () => {
    update(id, value, sk);
    setShowInput(false);
  }, [update, id, value, sk]);

  return (
    <div>
      {!showInput && (
        <span>
          <span className={text}>{text}</span>
          <IconPointer
            size="small"
            variant="edit"
            role="edit-icon"
            onClick={() => {
              setShowInput(true);
            }}
          />
        </span>
      )}
      {showInput && (
        <>
          <DropdownInputIconWrap>
            <TableInlineDropdown
              menuItems={languages}
              onItemSelect={setValue}
              value={text}
              className="dropdown"
              role="dropdown"
            />
            <button onClick={handleSave} >
              <InputIcon fill="serena" size="small" variant="check" role="check-icon" />
            </button>
            <button
              onClick={() => {
                setShowInput(false);
              }}
            >
              <InputIcon fill="jordan" size="small" variant="x" role="x-icon" />
            </button>
          </DropdownInputIconWrap>
        </>
      )}
    </div>
  );
};

type ApiParamRowProps = {
  params: string[];
  id: string;
  update: Function;
  sk: string;
};

const ApiParametersRowInput: React.FC<ApiParamRowProps> = (props) => {
  const { params, id, update, sk } = props;
  const [open, setOpen] =  useState<boolean>(false);
  const [value, setValue] = useState<Array<string>>(params);
  const [chosenParams] = useState<Array<string>>(params);
  const [availableParams, setAvailableParams] = useState<Array<string>>([]);

  const { privateFetch } = useToken();

  const modalActions = {
    onClick: (): void => {
      update(id, value, sk);
      setOpen(false);
    },
    disabled: false,
    text: "Save"
  };

  const handleEditApiParams = useCallback(async () => {
    const url = `${apiURL}files/${id}`;

    const res = await privateFetch(url);
    const json = await res.json();
    setAvailableParams(json.apiParameters?.split(",") || []);
    setOpen(true);
  }, [id, privateFetch, apiURL]);

  return (
    <div>
      <span>
        <span>{chosenParams.join(", ")}</span>
        <IconPointer
          size="small"
          variant="edit"
          role="edit-icon"
          onClick={handleEditApiParams}
        />
      </span>
      { open &&
        <StyledModal
          handleClose={(): void => setOpen(false)}
          modalAction={modalActions}
        >
          <StyledDualListSelector
            availableOptionsTitle="Available API Parameters"
            chosenOptionsTitle="Selected API Parameters"
            availableOptions={availableParams.map(p => {
              return { id: p, name: p }
            })}
            chosenOptions={value.map(p => {
              return { id: p, name: p }
            })}
            onListChange={(availableOptions, chosenOptions) =>
              setValue(chosenOptions.map(p => {
                return p.name;
              }).sort())
            }
          />
        </StyledModal>
      }
    </div>
  );
};

type DeliveryFile = {
  extension?: string;
  format?: string;
  apiFormat?: string;
  apiLanguage?: string;
  apiParameters?: string;
  id: string;
  name: string;
  type: string;
};

type DeliveryData = {
  files: Array<DeliveryFile>;
  deliveryMethod: string;
  clientName: string;
  lastUpdatedAt: string;
};

type DeliveryFilesProps = {
  clientId: string;
  deliveryId: string;
  delivery: DeliveryData;
  updateClientDelivery: Function;
};

const DeliveryFiles: React.FC<DeliveryFilesProps> = (props) => {
  const { clientId, deliveryId, delivery, updateClientDelivery } = props;
  const [addFiles, setAddFiles] = useState<boolean>(false);
  const [selectAll, setSelectAll] = useState<boolean>(false);
  const [filesSelected, setFilesSelected] = useState<boolean>(false);

  const [batchInputDirectory, setBatchInputDirectory] = useState<string>('');

  const { privateFetch } = useToken();

  const handleSelectAll = useCallback(() => {
    const checkAll = document.querySelector(
      '.checkbox-wrap input'
    ) as HTMLInputElement;

    setSelectAll(checkAll?.checked);
  }, []);

  const url = `${apiURL}clients/${clientId}/deliveries/${deliveryId}`;

  const deleteFiles = async (fileList: Array<any>) => {
    try {
      const requestBody = {
        files: fileList,
        operationType: 'delete',
      };

      const settings = {
        method: 'POST',
        body: JSON.stringify(requestBody),
      };

      await privateFetch(url, settings)
      await updateClientDelivery();
    } catch (e) {
      console.log(e);
      return e;
    }
  };

  const updateFiles = async (fileList: Array<any>) => {
    try {
      const requestBody = {
        files: fileList,
        directory: batchInputDirectory,
        operationType: 'update',
      };

      const settings = {
        method: 'POST',
        body: JSON.stringify(requestBody),
      };

      await privateFetch(url, settings);
      await updateClientDelivery();
    } catch (e) {
      console.log(e);
      return e;
    }
  };

  const getFileList = () => {
    const rows = document.querySelectorAll('.row-check-wrapper');

    const fileList: Array<any> = [];

    rows.forEach((checkBox) => {
      const checkInput = checkBox.querySelector('input') as HTMLInputElement;
      if (checkInput.checked) {
        fileList.push({
          id: checkInput.getAttribute("data-id"),
          sk: checkInput.getAttribute("data-sk"),
          fileName: checkInput.getAttribute("data-filename"),
          fileExtension: checkInput.getAttribute("data-ext")
        });
      }
    });

    return fileList;
  };

  // Updates the language of an HTTP Post file
  const updateLanguage = async (fileId: string, apiLanguage: string, sk: string) => {
    const url = `${apiURL}clients/${clientId}/deliveries/${deliveryId}/${fileId}`;

    try {
      const requestBody = {
        sk: sk,
        apiLanguage: apiLanguage,
      };

      const settings = {
        method: 'PUT',
        body: JSON.stringify(requestBody),
      };

      await privateFetch(url, settings).then(() => {
        updateClientDelivery();
      });
    } catch (e) {
      console.log(e);
      return e;
    }
  };

  // Updates the parameters of an HTTP Post file
  const updateApiParameters = async (fileId: string, apiParamters: string[], sk: string) => {
    const url = `${apiURL}clients/${clientId}/deliveries/${deliveryId}/${fileId}`;

    try {
      const requestBody = {
        sk: sk,
        apiParameters: apiParamters,
      };

      const settings = {
        method: 'PUT',
        body: JSON.stringify(requestBody),
      };

      await privateFetch(url, settings).then(() => {
        updateClientDelivery();
      });
    } catch (e) {
      console.log(e);
      return e;
    }
  };

  const checkSelections = useCallback(() => {
    const hasSelected = document.querySelector(
      'input:checked'
    ) as HTMLInputElement;

    setFilesSelected(hasSelected !== null);
  }, []);

  const deleteSelected = useCallback(() => {
    const fileList = getFileList();
    deleteFiles(fileList);
  }, [apiURL, clientId, deliveryId, updateClientDelivery]);

  const applyBatchInput = useCallback(() => {
    const fileList = getFileList();
    updateFiles(fileList);
  }, [batchInputDirectory, apiURL, clientId, deliveryId, updateClientDelivery]);

  

  const tableColumns = [
    {
      key: 'id',
      label: '',
      render: (row: { id: string, sk: string, name: string, extension: string, apiFormat: string }) => {
        const extension = (delivery.deliveryMethod === 'HTTP_POST') ? `.${row.apiFormat}` : row.extension;

        return (
          <span className="row-check-wrapper">
            <Checkbox
              checked={selectAll}
              id={`${row.id}_${row.sk}`}
              dataAttributes={{ "data-sk": row.sk, "data-id": row.id , "data-filename": row.name, "data-ext": extension }}
              onChange={checkSelections}
            />
          </span>
        );
      },
    },
    {
      key: 'filename',
      label: 'File Name',
      render: (row: { name: string; extension: string }) => (delivery.deliveryMethod === "HTTP_POST") ? row.name : row.name + row.extension
    },
    {
      key: 'leagueDirectoryName',
      label: 'League',
      render: (row: { leagueDirectoryName: string }) => row.leagueDirectoryName,
      sortable: true,
    },
    {
      key: 'filetype',
      label: 'File Type',
      render: (row: { type: string }) => row.type,
    },
    {
      key: 'fileformat',
      label: 'File Format',
      render: (row: { format: string, apiFormat: string }) => (delivery.deliveryMethod === "HTTP_POST") ? row.apiFormat : row.format,
    },
  ];

  // Add extra columns depending on format
  if (delivery.deliveryMethod === "HTTP_POST") {
    tableColumns.push({
      key: 'apiLanguage',
      label: 'Language',
      // @ts-ignore
      render: (row: { apiLanguage: string; id: string, sk: string}) => {
        return <ApiLanguageRowInput id={row.id} text={row.apiLanguage} update={updateLanguage} sk={row.sk}/>;
      }
    });

    tableColumns.push({
      key: 'apiUrl',
      label: 'API Url',
      // @ts-ignore
      render: (row: { apiUrl: string }) => row.apiUrl,
    });

    tableColumns.push({
      key: 'parameters',
      label: 'Parameters',
      // @ts-ignore
      render: (row: { name: string; apiParameters: string, sk: string, id: string }) => {
        const params = (!row.apiParameters) ? [] : row.apiParameters.split(",");
        return <ApiParametersRowInput id={row.id} params={params} update={updateApiParameters} sk={row.sk}/>;
      },
    });
  }
  else {
    tableColumns.push({
      key: 'filerename',
      label: 'File Re-Name',
      // @ts-ignore
      render: (row: { renamed: string; id: string, sk: string }) => {
        const text = row.renamed || 'Not Set';
        const updateUrl = `${apiURL}clients/${clientId}/deliveries/${deliveryId}/${row.id}`;
        return <RowInput
                  text={text}
                  sk={row.sk}
                  updateUrl={updateUrl}
                  handleRename={updateClientDelivery}
                />;
      },
    });

    tableColumns.push({
      key: 'directory',
      label: 'File Directory',
      // @ts-ignore
      render: (row: { directory: string }) => {
        const text = row.directory || 'Not Set';
        return <span className={text}>{text}</span>;
      },
    });
  }

  const tableData = delivery?.files.map((file) => {
    return {
      data: file,
    };
  });

  const handleCancelAddFiles = useCallback(() => {
    setAddFiles(false);
  }, []);

  const handleSaveAddFiles = useCallback(async () => {
    await updateClientDelivery();
    setAddFiles(false);
  }, [updateClientDelivery]);

  const handleClickAddFiles = useCallback(() => {
    setAddFiles(true);
  }, []);

  const handleBatchInputChange = useCallback((e : React.ChangeEvent<HTMLInputElement>) => {
    setBatchInputDirectory(e.currentTarget.value);
  }, []);

  return (
    <>
      { addFiles
        ? <AddFiles
            clientId={clientId}
            deliveryId={deliveryId}
            lastUpdated={delivery.lastUpdatedAt}
            deliveryMethod={delivery.deliveryMethod}
            onCancel={handleCancelAddFiles}
            onSave={handleSaveAddFiles}
          />
        : <FormWrap>
            <HeaderDiv>
              <h1>FILES</h1>
              <ButtonWrap>
                <Button onClick={handleClickAddFiles}>
                  Add Files
                </Button>
              </ButtonWrap>
              <LastUpdated>
                Last updated on{' '}
                {delivery.lastUpdatedAt && dateRender(delivery.lastUpdatedAt)}
              </LastUpdated>
              <BatchInputWrap>
                { delivery.deliveryMethod !== "HTTP_POST" &&
                  <>
                    <BatchInput
                      label="Change File Directory  (Select files below)"
                      helperText={"File Directory must not start and must end with \"/\""}
                      id="batch_input"
                      onChange={handleBatchInputChange}
                    />
                    <Button
                      // File directory cannot start with /, must end with a / and is allowed to be blank
                      disabled={(!filesSelected && !selectAll) || batchInputDirectory.startsWith("/") || !(batchInputDirectory === "" || batchInputDirectory.endsWith("/"))}
                      onClick={applyBatchInput}
                      variant="primary-black"
                    >
                      Apply
                    </Button>
                  </>
                }
                <DeleteButtonWrap>
                  <span>Delete Files (Select files below)</span>
                  <Button
                    disabled={!filesSelected && !selectAll}
                    onClick={deleteSelected}
                  >
                    Delete Selected Files
                  </Button>
                </DeleteButtonWrap>
              </BatchInputWrap>
            </HeaderDiv>

            <CheckBoxWrap className="checkbox-wrap">
              <Checkbox
                onChange={handleSelectAll}
                id="selectall"
                label="Select All"
              />
            </CheckBoxWrap>
            {tableData && (
              <TableWrapper>
                <Table columns={tableColumns} data={tableData} keyprop={(delivery.deliveryMethod === "HTTP_POST") ? "sk" : "id"} />
              </TableWrapper>
            )}
          </FormWrap>
      }
    </>
  );
};

export default DeliveryFiles;
