import React, { useCallback, useContext, useState } from 'react';
import { apiURL, useToken } from '@/helpers/apiURL';
import styled from 'styled-components';
import {
  Modal,
  Dropdown,
  Input,
} from '@stats/playbook-components';
import ReactDOM from 'react-dom';
import DeliveryMethodsContext from '@/DeliveryMethodsContext';
import EventBus from '@/helpers/eventBus';

const StyledModal = styled(Modal)`
  && {
    width: 400px;
    height: auto;
    border-top: 4px solid red;
  }
`;

const StyledDropdown = styled(Dropdown)`
  && {
    z-index: 9;
    margin-bottom: 15px;
  }

  &.error .dropdown-field {
    border: solid 1px rgba(238, 8, 49, 1);
  }
`;

type Delivery = {
  name: string;
  deliveryMethod: string;
  id: string;
};

type AddDeliveryModalProps = {
  handleClose: () => void;
  clientId: string;
  delivery?: any;
  clone?: boolean;
};

const AddDeliveryModal: React.FC<AddDeliveryModalProps> = (props) => {
  const { handleClose, delivery, clientId, clone } = props;
  const { privateFetch } = useToken();
  const deliveryMethods = useContext(DeliveryMethodsContext);

  const [modalDelivery, setModalDelivery] = useState<Delivery>({
    name: '',
    deliveryMethod: '',
    ...delivery,
  });

  const [formError, setFormError] = useState<boolean>(false);

  const addDelivery = useCallback(async () => {
    const url = `${apiURL}clients/${clientId}/deliveries`;
    const { name, deliveryMethod, id } = modalDelivery;

    try {
      const postData = {
        deliveryMethod: deliveryMethod,
        name: name,
      };

      const postBody = clone
        ? {
            ...postData,
            copyFrom: { clientId: clientId, deliveryId: id },
          }
        : postData;

      const settings = {
        method: 'POST',
        body: JSON.stringify(postBody),
      };

      await privateFetch(url, settings);
      handleClose();
    } catch (e) {
      EventBus.dispatch('notify', { variant: 'error', message: 'Failed to create delivery' });
      setFormError(true);
    }
  }, [clientId, modalDelivery, privateFetch, delivery]);

  const onMethodChange = useCallback((type: string) => {
    setModalDelivery({ ...modalDelivery, deliveryMethod: type });
  }, [modalDelivery]);

  const onNameChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setModalDelivery({ ...modalDelivery, name: e.currentTarget.value.split(' ').join('_') });
  }, [modalDelivery]);

  return (
    ReactDOM.createPortal(
      <StyledModal
        title={`${clone ? 'Clone' : 'Add'} Delivery`}
        handleClose={handleClose}
        modalAction={{
          text: `${clone ? 'Clone' : 'Add'} Delivery`,
          onClick: addDelivery,
          disabled: modalDelivery.name === '' || modalDelivery.deliveryMethod === '',
        }}
      >
        <StyledDropdown
          label="Delivery Type"
          value={modalDelivery.deliveryMethod}
          menuItems={deliveryMethods.map(({ method }) => ({ value: method }))}
          onItemSelect={onMethodChange}
          required
          excludeDeselectOption
          className={(formError && !modalDelivery.deliveryMethod) ? 'error': undefined}
        />
        <Input
          label="Name"
          value={modalDelivery.name}
          onChange={onNameChange}
          required
          state={(formError && !modalDelivery.name) ? 'error': undefined}
        />
      </StyledModal>,
      document.body
    )
  );
};

export default AddDeliveryModal;
