import React, { useState, useCallback, useEffect } from 'react';
import styled from 'styled-components';
import {
  AddNoteModal,
  EditNoteModal,
  DeleteNoteModal,
} from '@components/Modals';
import { Icon, Button } from '@stats/playbook-components';
import Table from '@components/Table';
import Pagination from '@components/Table/Pagination';
import Loader from '@components/Loader';
import { apiURL, useToken } from '@/helpers/apiURL';

const LastUpdated = styled.p`
  float: left;
  font-size: 10px;
  font-style: italic;
  color: #6f6f6f;
  margin-bottom: 20px;
  margin-top: 0;
`;

const ButtonWrap = styled.div`
  display: inline-block;
  width: 49%;
  text-align: right;
`;

const TableWrapper = styled.div`
  && {
    table {
      thead {
        tr {
          th:first-child {
            width: 160px;
          }
          th:nth-child(2) {
            width: 180px;
          }
          th:nth-child(3) {
            width: 80px;
          }
          th:nth-child(4) {
            width: 140px;
          }
          th:nth-child(6) {
            width: 400px;
          }
          th:last-child {
            width: 100px;
          }
        }
      }
    }
    td {
      vertical-align: top;
    }
    td:last-child {
      vertical-align: top;
      text-align: right;
      svg {
        margin-left: 2px;
        margin-right: 14px;
      }
    }
  }
`;

const IconPointer = styled(Icon)`
  cursor: pointer;
`;

const dateRender = (datetime: string) => {
  const format = new Intl.DateTimeFormat('en-US', {
    month: 'long',
    day: 'numeric',
    year: 'numeric',
  });

  return format.format(new Date(datetime));
};

type ClientNotesProps = {
  clientId: string;
  lastUpdatedAt: string;
  deliveries: Array<any> | [];
};

type NotesData = {
  Items: [];
  CurrentPage: number;
  PageSize: number;
  TotalPages: number;
  error?: string;
};

const ClientNotes: React.FC<ClientNotesProps> = (props) => {
  const { clientId, lastUpdatedAt, deliveries } = props;
  const [openAddNoteModal, setOpenAddNoteModal] = useState<any>(null);
  const [openEditNoteModal, setOpenEditNoteModal] = useState<any>(null);
  const [openDeleteNoteModal, setOpenDeleteNoteModal] = useState<any>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(1);
  const { privateFetch } = useToken();

  const [notes, setNotes] = useState<NotesData>({
    Items: [],
    CurrentPage: 1,
    PageSize: 20,
    TotalPages: 1,
  });
  
  // Retrieves client notes from the API
  const getNotes = useCallback(async () => {
    const timeoutId = setTimeout(() => setIsLoading(true), 500);
    const res = await privateFetch(`${apiURL}clients/${clientId}/notes?page=${page}&size=20`);
    const json = await res.json();
    if (!json.error) {
      setNotes(json);
    } else {
      setNotes({
        Items: [],
        CurrentPage: 1,
        PageSize: 20,
        TotalPages: 1,
      });
    }
    setIsLoading(false);
    clearTimeout(timeoutId);
  }, [page, clientId, privateFetch, apiURL]);

  useEffect(() => {
    getNotes()
  }, [getNotes]);

  const tableColumns = [
    {
      key: 'createdAt',
      label: 'Created (CT)',
      render: (row: { createdAt: string }) => {
        const date = new Date(row.createdAt);
        return date.toLocaleString('en-US', { timeZone: "America/Chicago"});
      },
    },
    {
      key: 'updatedAt',
      label: 'Last Updated (CT)',
      render: (row: { lastUpdatedAt: string }) => {
        const date = new Date(row.lastUpdatedAt);
        return date.toLocaleString('en-US', { timeZone: "America/Chicago"});
      },
    },
    {
      key: 'user',
      label: 'User',
      render: (row: { user: string }) => row.user,
    },
    {
      key: 'type',
      label: 'Type',
      render: (row: { deliveryName: string }) => {
        return row.deliveryName ? 'Delivery' : 'Client';
      },
    },
    {
      key: 'name',
      label: 'Delivery Name',
      render: (row: { deliveryName: string }) => row.deliveryName,
    },
    {
      key: 'text',
      label: 'Note',
      render: (row: { text: string }) => row.text,
    },
    {
      key: 'controls',
      label: '',
      render: (row: any) => (
        <>
          <IconPointer
            size="medium"
            variant="edit"
            role="edit-icon"
            onClick={() => setOpenEditNoteModal(row)}
          />

          <IconPointer
            size="medium"
            variant="delete"
            role="delete-icon"
            onClick={() => setOpenDeleteNoteModal(row)}
          />
        </>
      ),
    },
  ];

  const items = notes?.Items?.reverse() || [];

  const tableData = items.map((item: { id: string }) => {
    return {
      data: item,
      subData: {},
    };
  });

  const handleClickAddNote = useCallback(() => {
    setOpenAddNoteModal(true)
  }, []);

  const handleCloseAddNoteModal = useCallback(() => {
    setOpenAddNoteModal(null);
  }, []);

  const handleCloseDeleteNoteModal = useCallback(() => {
    setOpenDeleteNoteModal(null);
  }, []);

  const handleCloseEditNoteModal = useCallback(() => {
    setOpenEditNoteModal(null);
  }, []);

  return (
    <>
      <h1>Notes</h1>
      <ButtonWrap>
        <Button onClick={handleClickAddNote}>Add Note</Button>
      </ButtonWrap>

      <LastUpdated>
        Last updated on {lastUpdatedAt && dateRender(lastUpdatedAt)}
      </LastUpdated>

      { isLoading
        ? <Loader />
        : <>
            <TableWrapper>
              <Table columns={tableColumns} data={tableData} keyprop="sk" />
            </TableWrapper>
            {notes?.TotalPages > 0 && (
              <Pagination
                page={page}
                totalPages={notes?.TotalPages}
                setPage={setPage}
              />
            )}
          </>
      }
      {openAddNoteModal && (
        <AddNoteModal
          handleClose={handleCloseAddNoteModal}
          clientId={clientId}
          deliveries={deliveries}
        />
      )}
      {!!openEditNoteModal && (
        <EditNoteModal
          clientId={clientId}
          handleClose={handleCloseEditNoteModal}
          note={openEditNoteModal}
          deliveries={deliveries}
        />
      )}
      {!!openDeleteNoteModal && (
        <DeleteNoteModal
          handleClose={handleCloseDeleteNoteModal}
          clientId={clientId}
          note={openDeleteNoteModal}
        />
      )}
    </>
  );
};

export default ClientNotes;
