import styled from 'styled-components';

export const LastUpdated = styled.p`
  font-size: 10px;
  font-style: italic;
  color: #6f6f6f;
  margin-bottom: 20px;
  margin-top: 0;
`;

export const ButtonWrap = styled.div`
  display: inline-block;
  width: 49%;
  text-align: right;
`;

export const DeleteButtonWrap = styled.div`
  display: inline-block;

  button { 
    margin-top: 12px;
  }

  > span {
    font-size: 10px;
    height: 7px;

    display: block;
    color: inherit;
    margin-left: 15px;
  }

  .disabled {
      cursor: not-allowed;
    }
`;

export const CheckBoxWrap = styled.div`
  label {
    display: inline-block;
    margin: 10px;
  }
`;

export const StyledSubNav = styled.ul`
  list-style-type: none;
  padding: 0;
  margin: 0;
  li {
    display: inline-block;

    a {
      width: auto;
      height: 24px;
      margin: 0 10px 2px 9px;
      padding: 0 12px;
      font-family: Roboto;
      font-size: 14px;
      font-weight: 400;
      line-height: 1.71;
      letter-spacing: normal;
      color: #222222;
      display: inline-block;
      text-decoration: none;

      &.active {
        border-bottom: 2px solid #e5202f;
      }
    }
  }
`;
