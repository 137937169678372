import React from 'react';

import { ChangeHistoryList } from '@components/ChangeHistoryList';
type ClientChangeHistoryProps = { clientId: string };

const ClientHistory: React.FC<ClientChangeHistoryProps> = (props) => {
  const { clientId } = props;
  return (
    <>
      <div>
        {clientId !== '' && (
          <ChangeHistoryList historyURL={`clients/${clientId}/history`} />
        )}
      </div>
    </>
  );
};

export default ClientHistory;
