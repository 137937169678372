import React from 'react';
import styled from 'styled-components';
import { palette, typography } from '@stats/playbook-components';
import classNames from 'classnames';

type LabelHTMLProps = React.HTMLProps<HTMLLabelElement>;
type StyledLabelType = {};
type StyledLabelProps = StyledLabelType & LabelHTMLProps;

const StyledLabel = styled.label<StyledLabelType>`
  ${typography};
  font-size: 10px;
  line-height: 1.6;
  height: 16px;

  display: block;
  color: inherit;
  background: transparent;

  &.disabled {
    color: ${palette['sp-mid-gray']};
  }

  .required {
    color: ${palette['jordan']};
    padding-left: 4px;
  }
`;

type LabelType = {
  label: string;
  required?: boolean;
  disabled?: boolean;
};
export type LabelProps = LabelType & StyledLabelProps;

export const Label: React.FC<LabelProps> = (props) => {
  const { ref, as, label, ...rest } = props;
  const labelClass = classNames({
    disabled: props.disabled,
  });

  return (
    <StyledLabel className={labelClass} {...rest}>
      {label}
      {props.required && <span className={'required'}>*</span>}
    </StyledLabel>
  );
};
