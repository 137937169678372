import React, { useCallback, useEffect, useState } from 'react';

import { Icon, IconType } from '@stats/playbook-components';
import styled from 'styled-components';

const StyledCheckbox = styled.input`
  appearance: none;
  border: none;
  background: none;
  width: 0px;
  height: 0px;
  margin: 0;
`;

const StyledLabel = styled.label`
  display: block;
  cursor: pointer;
`;

const StyledIcon = styled(Icon)`
  margin: 0 8px 0 0;
  vertical-align: bottom;
`;

interface CheckboxProps {
  checked?: boolean;
  disabled?: boolean;
  label?: string | React.ReactElement;
  id?: string;
  className?: string;
  size?: 'small' | 'medium';
  showLabelHover?: boolean;
  onClick?: (e: React.MouseEvent | React.ChangeEvent) => void;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  dataAttributes?: any
}

const Checkbox: React.FC<CheckboxProps> = ({
  checked = false,
  disabled = false,
  label = '',
  size = 'small',
  id = '',
  ...props
}) => {
  const { onChange } = props;
  const [isChecked, setIsChecked] = useState(checked);

  let iconVarient: IconType["variant"] = isChecked ? "checkbox-selected" : "checkbox-unselected";

  const toggleChecked = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setIsChecked(!isChecked);
    onChange && onChange(e);
  }, [isChecked, onChange]);

  useEffect(() => {
    setIsChecked(checked);
  }, [checked]);

  return (
    <StyledLabel>
      <StyledCheckbox
        id={id}
        type="checkbox"
        onChange={toggleChecked}
        checked={isChecked}
        disabled={disabled}
        {...props.dataAttributes}
      />
      <StyledIcon variant={iconVarient} size={size} />
      <span className="checkbox__label-text">{label}</span>
    </StyledLabel>
  );
};

export default Checkbox;
