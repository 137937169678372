import React, { useEffect, useState, useCallback } from 'react';
import Table from '@components/Table';
import Pagination from '@components/Table/Pagination';
import Loader from '@components/Loader';
import DateFilter from '@components/DateFilter';
import { apiURL, useToken } from '@/helpers/apiURL';
import EventBus from '@/helpers/eventBus';
import { subMonths } from 'date-fns';
import { getTimezoneOffset } from 'date-fns-tz';

// Negative value in ms
const chicagoTimeOffset = getTimezoneOffset('America/Chicago');

type ChangeHistoryData = {
  Items: any[];
  CurrentPage: number;
  PageSize: number;
  TotalPages: number;
  error?: string;
};

type ChangeHistoryListProps = {
  historyURL: string;
};

const ChangeHistoryList: React.FC<ChangeHistoryListProps> = (props) => {
  const { historyURL } = props;

  const [changehistory, setChangeHistory] = useState<ChangeHistoryData>({
    Items: [],
    CurrentPage: 1,
    PageSize: 20,
    TotalPages: 1,
  });

  const [page, setPage] = useState(1);
  const [from, setFrom] = useState(subMonths((new Date(Date.now() + chicagoTimeOffset)).setUTCHours(0, 0, 0, 0) - chicagoTimeOffset, 1).getTime());
  const [to, setTo] = useState((new Date(Date.now() + chicagoTimeOffset)).getTime() - chicagoTimeOffset);
  const [isLoading, setIsLoading] = useState(true);

  // Handles changes made to the to/from dates
  const handleDateChange = useCallback((e: any) => {
    setFrom(e.from);

    if (e.to > (new Date()).getTime() - chicagoTimeOffset) {
      setTo((new Date()).getTime() - chicagoTimeOffset);
    }
    else {
      setTo(e.to);
    }
    
    setPage(1);
  }, []);

  const parseChangeHistory = (json: any) => {
    const data = {
      Items: json.Items || json,
      CurrentPage: json.CurrentPage || 1,
      PageSize: 20,
      TotalPages: json.TotalPages || 1,
    };

    setChangeHistory(data);
  };

  const { privateFetch } = useToken();
  const getHistory = useCallback(async () => {
    const timeoutId = setTimeout(() => setIsLoading(true), 500);
    const size = 20;

    let url = `${apiURL}${historyURL}?page=${page}&size=${size}&from=${from}&to=${to}`;

    await privateFetch(url).then(async (response) => {
      const json = ((await response.json()) as unknown) as ChangeHistoryData;
      if (json && !json.error) {
        parseChangeHistory(json);
      } else {
        EventBus.dispatch('notify', { variant: 'error', message: 'Failed to load Change History' });
        setChangeHistory({
          Items: [],
          CurrentPage: 1,
          PageSize: 20,
          TotalPages: 1,
        });
      }
    }).catch(() => {
      EventBus.dispatch('notify', { variant: 'error', message: 'Failed to load Change History' });
    });

    setIsLoading(false);
    clearTimeout(timeoutId);
  }, [page, from, to, historyURL, privateFetch]);

  useEffect(() => {
    getHistory();
  }, [getHistory]);

  // action: "Add"
  // createdAt: "2021-05-24T20:15:46.000Z"
  // date: 1621887346000
  // description: "CBS Sportsline\\cbs-sports-graphics\\BRAZ_FINALBOX$.XML"
  // lastUpdatedAt: "2021-05-24T20:15:46.000Z"
  // month: "2021-4"
  // object: "file delivery"
  // pk: "CLIENT683"
  // sk: "FTP_PULL_CBS-SPORTS-GRAPHICS#FILE DELIVERY#ADD#1621887346000"

  const tableColumns = [
    {
      key: 'date',
      label: 'Time (CT)',
      render: (row: { date: string }) => {
        return new Date(row.date).toLocaleString('en-US', { timeZone: "America/Chicago"});
      },
    },
    {
      key: 'action',
      label: 'Action',
      render: (row: { action: string }) => row.action,
    },
    {
      key: 'object',
      label: 'Object',
      render: (row: { object: string }) => row.object,
    },
    {
      key: 'description',
      label: 'Change',
      render: (row: { description: string }) => row.description,
    },
    {
      key: 'pk',
      label: 'Client',
      render: (row: { pk: string }) => row.pk,
    },
    {
      key: 'user',
      label: 'User',
      render: (row: { user: string }) => row.user,
    },
  ];

  const tableData = changehistory?.Items?.map((item: { id: string }) => {
    return {
      data: item,
      subData: {},
    };
  });

  return (
    <>
      <DateFilter
        fromDateVal={new Date(from)}
        toDateVal={new Date(to)}
        setDateFilter={handleDateChange}
      />
      { isLoading
        ? <Loader />
        : <>
            <Table columns={tableColumns} data={tableData} keyprop="date" />
            {changehistory?.TotalPages > 0 && (
              <Pagination
                page={page}
                totalPages={changehistory?.TotalPages}
                setPage={setPage}
              />
            )}
          </>
      }
    </>
  );
};

export default ChangeHistoryList;
