import { createContext } from 'react';
// context for storing delivery method menu items.

export type DeliveryMethodsProps = Array<{
  text: string;
  method: string
}>;

export const deliveryMethodsDefault = [];

const DeliveryMethodsContext = createContext<DeliveryMethodsProps>(deliveryMethodsDefault);

export default DeliveryMethodsContext;