import React, { useCallback, useState } from 'react';
import './react-datepicker.css'; // pulled from react-datepicker/dist/react-datepicker.css
import styled from 'styled-components';
import { Button, palette } from '@stats/playbook-components';
import { default as ReactDatePicker } from 'react-datepicker';
import { subDays } from 'date-fns';
import { getTimezoneOffset } from 'date-fns-tz'

// Will be a negative value in ms
const chicagoTimeOffset = getTimezoneOffset('America/Chicago');


const StyledDatePicker = styled.div`
  .react-datepicker__triangle {
    display: none;
  }

  .react-datepicker {
    box-shadow: 11px 9px 40px 6px rgba(105, 105, 105, 0.56);
    -webkit-box-shadow: 11px 9px 40px 6px rgba(105, 105, 105, 0.56);
    -moz-box-shadow: 11px 9px 40px 6px rgba(105, 105, 105, 0.56);
  }

  .react-datepicker {
    border: none;
    border-radius: 0;
  }

  .react-datepicker__header {
    background-color: ${palette['sp-white']};
    text-transform: uppercase;
    font-size: 14px;
    font-family: 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
      'Droid Sans', 'Helvetica Neue', sans-serif;
  }

  .react-datepicker__header {
    background-color: ${palette['sp-white']};
  }

  .react-datepicker__current-month {
    margin-top: 2px;
    text-transform: uppercase;
    font-size: 14px;
    font-family: 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
      'Droid Sans', 'Helvetica Neue', sans-serif;
  }

  .react-datepicker__month,
  .react-datepicker__quarter {
    &--in-selecting-range,
    &--in-range {
      border-radius: 0;
      background-color: ${palette['jordan-50']};
    }
  }

  .react-datepicker__day--in-selecting-range,
  .react-datepicker__day--in-range {
    border-radius: 0;
    background-color: ${palette['jordan-50']};
  }

  .react-datepicker__day--selected,
  .react-datepicker__day--range-start,
  .react-datepicker__day--range-end {
    border-radius: 0;
    background-color: ${palette['jordan-90']};
  }

  .react-datepicker__input-time-container {
    width: 90%
  }

  .react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input input {
    width: 80%;
}

  input {
    width: 190px;
    height: 32px;
    background-color: rgba(242, 242, 242, 1);
    margin-right: 24px;
    box-sizing: border-box;
    border-radius: 2px;
    border: solid 1px rgba(226, 226, 226, 1);
    padding-left: 9px
  }

  > div {
    display: inline-block;
  }

  > span {
    display: inline-flex;
  }
`;

const FilterWrap = styled.div`
  margin-bottom: 24px;

  button.primary-black {
    display: inline-block;
  }
`;

type DateFilterProps = {
  setDateFilter: Function;
  fromDateVal: Date;
  toDateVal: Date;
  maxDaysInPast?: number;
};

const DateFilter: React.FC<DateFilterProps> = (props) => {
  const { setDateFilter, fromDateVal, toDateVal, maxDaysInPast } = props;
  const [from, setFrom]: any = useState(fromDateVal.getTime()); // Chicago time
  const [to, setTo]: any = useState(toDateVal.getTime()); // Chicago time
  const [wasChanged, setWasChanged] = useState(false);

  // 23:00 + 6hrs - 5hrs
  const fromDisplay = (from == null) ? null : new Date(from + new Date(from).getTimezoneOffset() * 60000 + chicagoTimeOffset);
  const toDisplay = (to == null) ? null : new Date(to + new Date(to).getTimezoneOffset() * 60000 + chicagoTimeOffset);

  const onChangeStart = useCallback((d: Date) => {
    // 00:00 - 6hrs + 5hrs
    const newFrom = d.getTime() - d.getTimezoneOffset() * 60 * 1000 - chicagoTimeOffset;  // Convert from local time to chicago time
    if (newFrom > to) {
      setFrom(to - 1);
    }
    else {
      setFrom(newFrom);
    }
    setWasChanged(true);
  }, [to]);

  const onChangeEnd = useCallback((d: Date) => {
    const newTo = d.getTime() - d.getTimezoneOffset() * 60 * 1000 - chicagoTimeOffset
    if (newTo < from) {
      setTo(from + 1);
    }
    else {
      setTo(newTo);
    }
    setWasChanged(true);
  }, [from]);

  const onClick = useCallback(() => {
    setDateFilter({ from: from, to: to });
    setWasChanged(false);
  }, [from, to]);

  return (
    <FilterWrap>
      <StyledDatePicker className={'datepicker-wrapper'}>
        <span>
          <ReactDatePicker
            selected={fromDisplay}
            onChange={onChangeStart}
            minDate={(maxDaysInPast) ? subDays(new Date(), maxDaysInPast) : undefined}
            maxDate={new Date()}
            startDate={fromDisplay}
            endDate={toDisplay}
            showTimeInput
            selectsStart
            dateFormat="MMMM d, yyyy h:mm aa"
          />

          <ReactDatePicker
            selected={toDisplay}
            onChange={onChangeEnd}
            minDate={(maxDaysInPast) ? subDays(new Date(), maxDaysInPast) : undefined}
            maxDate={new Date()}
            startDate={fromDisplay}
            endDate={toDisplay}
            showTimeInput
            selectsEnd
            dateFormat="MMMM d, yyyy h:mm aa"
          />
        </span>
        <Button
          onClick={onClick}
          variant="primary-black"
          disabled={!wasChanged}
        >
          Set Dates
        </Button>
      </StyledDatePicker>
    </FilterWrap>
  );
};

export default DateFilter;