import React from 'react';
import { Loader as PlaybookLoader } from '@stats/playbook-components';
import styled from 'styled-components';

const LoaderWrapper = styled.div`
  display: grid;
  justify-content: center;
  align-items: center;
  height: 200px;
`;

const Loader: React.FC<{}> = () => {
  return (
    <LoaderWrapper>
      <PlaybookLoader role="img" />
    </LoaderWrapper>
  );
};

export default Loader;