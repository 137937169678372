import React, { useCallback, useState } from 'react';
import styled from 'styled-components';
import { Modal, palette } from '@stats/playbook-components';
import classNames from 'classnames';
import ReactDOM from 'react-dom';

const StyledWrapper = styled.div`
  width: 32px;
  cursor: pointer;
`;

const StyledSwitch = styled.div`
  background-color: ${palette['sp-light-gray-50']};
  border-radius: 100px;
  padding: 4px;
  transition: background-color 0.5s ease-in-out;

  .checked & {
    background-color: ${palette.serena};
  }
`;

const StyledHandle = styled.div`
  border-radius: 100px;
  height: 10px;
  width: 10px;
  background-color: ${palette['sp-mid-gray']};
  transition: all 0.5s ease-in-out;
  transition-property: background-color, transform;
  .checked & {
    background-color: ${palette['sp-white']};
    opacity: 0.8;
    transform: translate(16px, 0);
  }
`;

const StyledModal = styled(Modal)`
  && {
    width: 400px;
    height: 175px;
    border-top: 4px solid red;
  }
`;

type CheckboxAttributes = React.HTMLAttributes<HTMLInputElement>;
type SwitchType = {
  modalTitle: string,
  modalConfirmText: string,
  modalText: string;
};
type SwitchProps = CheckboxAttributes & SwitchType;

const ConfirmSwitch: React.FC<SwitchProps> = (props) => {
  const { onClick, modalTitle, modalConfirmText, modalText, ...rest } = props;

  const [checked, changeChecked] = useState<boolean>(
    props.defaultChecked || false
  );
  const [open, setOpen] = useState(false);

  const toggleChecked = useCallback((event: React.MouseEvent<HTMLInputElement, MouseEvent>) => {
    // Display confirmation modal
    if (checked) {
      setOpen(true);
    }
    else {
      changeChecked(true);
      onClick && onClick(event);
    }
  }, [checked, onClick]);

  const handleClose = useCallback(() =>  {
    setOpen(false);
    changeChecked(true);
  }, []);

  const modalOnClick = useCallback(() => {
    changeChecked(false);
    // @ts-ignore
    onClick && onClick();
    setOpen(false);
  }, [onClick]);

  const switchClassName = classNames('slim', { checked: checked });
  return (
    <>
      <StyledWrapper
        className={switchClassName}
        onClick={toggleChecked}
        role="toggle"
        {...rest}
      >
        <input
          type={'checkbox'}
          defaultChecked={checked}
          style={{ display: 'none' }}
        />
        <StyledSwitch>
          <StyledHandle />
        </StyledSwitch>
      </StyledWrapper>
      {open &&
        ReactDOM.createPortal(
          <StyledModal
            variant="error"
            title={modalTitle}
            handleClose={handleClose}
            modalAction={{
              text: modalConfirmText,
              onClick: modalOnClick,
            }}
          >
            {modalText} 
          </StyledModal>,
          document.body
        )}
    </>
  );
};

export default ConfirmSwitch;
