import React, { useCallback } from 'react';
import { apiURL, useToken } from '@/helpers/apiURL';
import EventBus from '@/helpers/eventBus';
import styled from 'styled-components';
import { Modal } from '@stats/playbook-components';
import ReactDOM from 'react-dom';

const StyledModal = styled(Modal)`
  && {
    width: 400px;
    height: auto;
    border-top: 4px solid red;
  }
`;

type DeleteDeliveryModalProps = {
  handleClose: () => void;
  deliveryId: string;
  clientId: string;
};

const DeleteDeliveryModal: React.FC<DeleteDeliveryModalProps> = (props) => {
  const { handleClose, deliveryId, clientId } = props;
  const { privateFetch } = useToken();

  const deleteDelivery = useCallback(async () => {
    const url = `${apiURL}clients/${clientId}/deliveries/${deliveryId}`;

    try {
      const settings = {
        method: 'DELETE',
      };

      await privateFetch(url, settings);
      handleClose();
    } catch (e) {
      EventBus.dispatch('notify', { variant: 'error', message: 'Failed to delete delivery' });
      return e;
    }
  }, [apiURL, clientId, deliveryId]);

  return (
    ReactDOM.createPortal(
      <StyledModal
        title="Remove Delivery From Client?"
        handleClose={handleClose}
        modalAction={{
          text: 'Remove Delivery',
          onClick: deleteDelivery,
        }}
      >
        Are you sure you want to remove this delivery? Systems consuming
        this delivery will no longer have access or will receive no new updates.
      </StyledModal>,
      document.body
    )
  );
};

export default DeleteDeliveryModal;
