import React, { useEffect, useState } from 'react';

import { Route, Switch, Redirect } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import Loader from '@components/Loader';
import { AppHeader as Header } from '@components/Header/SiteHeader';
import { AppFooter as Footer } from '@components/Footer/SiteFooter';
import { Wrapper } from '@components/Wrapper/Wrapper';
import FileFiltersContext, {
  fileFiltersDefault,
  FileFiltersProps,
} from '@/FileFiltersContext';
import DeliveryMethodsContext, {
  deliveryMethodsDefault,
  DeliveryMethodsProps,
} from '@/DeliveryMethodsContext';
import { apiURL, useToken } from '@/helpers/apiURL';
import  UnauthorizedPage  from './UnauthorizedPage';

// Import various pages
import OverviewChangeHistory from '@/pages/OverviewChangeHistory';
import OverviewClients from '@/pages/OverviewClients';
import OverviewFiles from '@/pages/OverviewFiles';
import OverviewDeliveries from '@/pages/OverviewDeliveries';
import ClientDetail from '@/pages/ClientDetail';
import OverviewDeliveryHistory from '@/pages/OverviewDeliveryHistory';
import OverviewLogs from '@/pages/OverviewLogs';
import FileRetrigger from '@/pages/OverviewFileRetrigger';

type HomePageProps = {};

const HomePage: React.FC<HomePageProps> = () => {
  const [fileFilters, setFileFilters] = useState<FileFiltersProps>(
    fileFiltersDefault
  );
  const [deliveryMethods, setDeliveryMethods] = useState<DeliveryMethodsProps>(
    deliveryMethodsDefault
  );
  const { isLoading, isAuthenticated, error, loginWithRedirect } = useAuth0();
  const { privateFetch } = useToken();
  useEffect(() => {
    let isMounted = true;
    if (!isLoading && !error && isAuthenticated) {
      privateFetch(`${apiURL}decode/files`)
        .then((res) => res.json())
        .then((json) => {
          if (isMounted) {
            setFileFilters(json);
          }
        });

      privateFetch(`${apiURL}decode/deliveries/methods`)
        .then((res) => res.json())
        .then((json) => {
          if (isMounted) {
            setDeliveryMethods(
              json.map((str: string) => ({
                text: str,
                method: str.toUpperCase().replace(' ', '_'),
              }))
            );
          }
        });
    }
    return () => {
      isMounted = false;
    };
  }, [isLoading, error, isAuthenticated, privateFetch]);

  if (isLoading) {
    return <Loader />;
  }

  if (error) {
   return <UnauthorizedPage />
  }

  if (!isAuthenticated) {
    loginWithRedirect();
  }

  // this is a wrapper for the rest of the application. Any static wrappers should be on this page.
  return (
    <Wrapper>
      <Header />
      <div id="content" role="main">
        <FileFiltersContext.Provider value={fileFilters}>
          <DeliveryMethodsContext.Provider value={deliveryMethods}>
            <Switch>
              {/* REDIRECTS */}
              <Route exact path="/">
                <Redirect to="/clients/overview" />
              </Route>
              <Route path={'/login'} component={OverviewClients}>
                <Redirect to="/clients/overview" />
              </Route>

              {/* OVERVIEW */}
              <Route path={'/clients/overview'} exact component={OverviewClients} />

              <Route
                path={'/changehistory/overview'}
                component={OverviewChangeHistory}
              />
              <Route path={'/files/overview'} component={OverviewFiles} />
              <Route path={'/deliveries/overview'} component={OverviewDeliveries} />
              <Route path={'/deliveryhistory/overview'} component={OverviewDeliveryHistory} />
              <Route path={'/deliveryhistory/export'} component={OverviewLogs} />
              <Route path={'/fileretrigger/overview'} component={FileRetrigger} />
            
              {/* CLIENT DETAIL */}
              <Route path={'/clients/:clientId'} component={ClientDetail} />
            </Switch>
          </DeliveryMethodsContext.Provider>
        </FileFiltersContext.Provider>
      </div>
      <Footer />
    </Wrapper>
  );
};

export default HomePage;
