import React, { useContext } from 'react';
import FileFiltersContext from '@/FileFiltersContext';
import { Dropdown } from '@stats/playbook-components';
import styled from 'styled-components';

const FormWrapper = styled.form`
  margin-bottom: 16px;

  & .dropdown + .dropdown {
    margin: 0 0 0 16px;
  }
`;

const StyledDropDown = styled(Dropdown)`
  display: inline-block;
  min-width: 220px;

  > div:last-child {
    z-index: 1;
  }
`;

const Row = styled.div`
  & + & {
    padding-top: 16px;
  }
`;

type FileFiltersProps = {
  setLeagueDirectory: Function;
  setFileFormat?: Function;
  setFileType: Function;
  setApiFormat?: Function;
  setApiLanguage?: Function;
  deliveryMethod?: string;
};

// Helper function to clean up string array
const menuItems = (str: string, ind: number) => ({ value: str });

const FileFilters: React.FC<FileFiltersProps> = (props) => {
  const {
    setLeagueDirectory,
    setFileFormat,
    setFileType,
    setApiFormat,
    setApiLanguage,
    deliveryMethod,
  } = props;

  const fileFilters = useContext(FileFiltersContext);

  const leagueDirectories = fileFilters.leagueDirectoryNames.map(menuItems);
  const fileFormats = (deliveryMethod && deliveryMethod === 'HTTP_POST') ? 
    ['API Endpoint','Headline Package','JSON','Picture'].map(menuItems) :
    fileFilters.formats.map(menuItems) ;
  const fileTypes = fileFilters.types.map(menuItems);
  const languages = fileFilters.languages.map(menuItems);
  const outputTypes = fileFilters.outputTypes.map(menuItems);

  return (
    <FormWrapper>
      <Row>
        <StyledDropDown
          includeSearch
          label="League Directories"
          onItemSelect={setLeagueDirectory}
          menuItems={leagueDirectories}
          value="AUSFL"
          className="dropdown"
          excludeDeselectOption
        />
        {setFileFormat &&
          <StyledDropDown
            label="File Format"
            onItemSelect={setFileFormat}
            menuItems={fileFormats}
            value={deliveryMethod && deliveryMethod === 'HTTP_POST' ? "API Endpoint" : "XML"}
            className="dropdown"
            excludeDeselectOption
          />
        }
        <StyledDropDown
          label="File Type"
          onItemSelect={setFileType}
          menuItems={fileTypes}
          value="nonlive"
          className="dropdown"
          excludeDeselectOption
        />
      </Row>
      {deliveryMethod && deliveryMethod === 'HTTP_POST' && (
        <Row>
          {setApiFormat &&
            <StyledDropDown
              label="Output Type"
              onItemSelect={setApiFormat}
              menuItems={outputTypes}
              value="JSON"
              className="dropdown"
              excludeDeselectOption
            />
          }
          <StyledDropDown
            label="Language"
            onItemSelect={setApiLanguage}
            menuItems={languages}
            value="English"
            className="dropdown"
            excludeDeselectOption
          />
        </Row>
      )}
    </FormWrapper>
  );
};

export default FileFilters;
