import React, { useCallback, useState } from 'react';
import {
  FormWrap,
  HeaderDiv,
  SmallDropdown,
  StyledDiv,
  StyledH2,
  StyledInput,
  StyledPassword, 
} from './ConfigStyled';

import { LastUpdated, ButtonWrap } from '@/helpers/styles';

import { Button, Modal, ModalVariantType } from '@stats/playbook-components';
import { NavLink } from 'react-router-dom';

import dateRender from '@/helpers/dateRender';
import { useToken, apiURL } from '@/helpers/apiURL';
import EventBus from '@/helpers/eventBus';

type DeliveryData = {
  files: Array<any>;
  deliveryMethod: string;
  clientId: string;
  clientName?: string;
  lastUpdatedAt?: string;
  id?: string;
  deliveryPassword?: string;
  deliveryUsername?: string;
  deliveryUrl?: string;
  ftp_create_directories?: string;
  ftp_create_temp_file?: string;
  ftp_delete_dest_file?: string;
  ftp_disconnect_command?: string;
  ftp_error_threshold?: string;
  ftp_file_name_case?: string;
  ftp_ftp_timeout?: string;
  ftp_key_exchange?: string;
  ftp_non_sam_emails_enabled?: string;
  ftp_passive_transfers?: string;
  port?: string;
  ftp_remote_ftp_address?: string;
  ftp_rename_temp_extension?: string;
  ftp_type?: string;
  ftp_close_connections?: string
  ftp_connection_timeout?: string;
  ftp_default_remote_path?: string;
  name?: string;
  reconnect_delay?: string;
  status?: string;
  ftp_debug_logs?: string;
};

type FormProps = {
  delivery: DeliveryData;
  handleFormChange: (e: any) => void;
}

const Form: React.FC<FormProps> = (props) => {
  const { delivery, handleFormChange } = props;

  const handleFtpTypeChange = useCallback((e: string) => {
    handleFormChange({
      target: {
        id: 'ftp_type',
        value: e
      }
    })
  }, [handleFormChange]);

  const handleFtpCloseConnectionsChange = useCallback((e: string) => {
    handleFormChange({
      target: {
        id: 'ftp_close_connections',
        value: e
      }
    })
  }, [handleFormChange]);

  const handleFtpDebugLogsChange = useCallback((e: string) => {
    handleFormChange({
      target: {
        id: 'ftp_debug_logs',
        value: e
      }
    })
  }, [handleFormChange]);

  return (
    <>
      <StyledDiv>
        { delivery.deliveryMethod === "FTP_PUSH" &&
          <StyledDiv>
            <StyledInput
              value={delivery.ftp_remote_ftp_address}
              label="I.P. Address"
              className="ipaddress"
              id="ftp_remote_ftp_address"
              onChange={handleFormChange}
            />
            <StyledInput
              value={delivery.port}
              label="Port"
              className="port"
              id="port"
              onChange={handleFormChange}
            />
          </StyledDiv>
        }
        <StyledInput
          value={delivery.deliveryUsername}
          id="deliveryUsername"
          label="Username"
          className="username"
          onChange={handleFormChange}
        />
        <StyledPassword
          value={delivery.deliveryPassword}
          label="Password"
          className="password"
          id="deliveryPassword"
          onChange={handleFormChange}
        />
        { delivery.deliveryMethod === "HTTP_POST" &&
          <StyledInput
            value={delivery.deliveryUrl}
            id="deliveryUrl"
            label="URL"
            className="url"
            onChange={handleFormChange}
          />
        }
        { delivery.deliveryMethod === "FTP_PUSH" &&
          <StyledInput
            value={delivery.ftp_default_remote_path}
            label="Home Path"
            className="homepath"
            id="ftp_default_remote_path"
            onChange={handleFormChange}
            helperText="Home path must be filled in to send files to subdirectories or to use “Create Directories”. Path should start and end with “/“."
          />
        }
      </StyledDiv>
      { delivery.deliveryMethod === "FTP_PUSH" &&
        <>
          <StyledH2>Additional Settings</StyledH2>
          <StyledDiv>
            <span id="ftp_type">
              <SmallDropdown
                value={delivery.ftp_type || 'FTP'}
                label="FTP Type"
                onItemSelect={handleFtpTypeChange}
                excludeDeselectOption
                menuItems={[
                  { name: 'FTP', value: 'FTP' },
                  { name: 'FTPS (Explicit)', value: 'FTPS (Explicit)' },
                  { name: 'FTPS (Implicit)', value: 'FTPS (Implicit)' },
                  { name: 'SFTP', value: 'SFTP' },
                ]}
                className="dropdown-container"
              />
            </span>

            <span id="ftp_close_connections">
              <SmallDropdown
                value={delivery.ftp_close_connections || 'false'}
                label="Close Connections"
                onItemSelect={handleFtpCloseConnectionsChange}
                excludeDeselectOption
                menuItems={[
                  { value: 'true', name: 'true' },
                  { value: 'false', name: 'false' },
                ]}
                className="dropdown-container"
              />
            </span>

            <span id="ftp_debug_logs">
              <SmallDropdown
                value={delivery.ftp_debug_logs || 'false'}
                label="Debug Logs"
                onItemSelect={handleFtpDebugLogsChange}
                excludeDeselectOption
                menuItems={[
                  { value: 'true', name: 'true' },
                  { value: 'false', name: 'false' },
                ]}
                className="dropdown-container"
              />
            </span>

            <span id="ftp_connection_timeout">
              <StyledInput
                value={delivery.ftp_connection_timeout}
                label="Connection Timeout (ms)"
                id="ftp_connection_timeout"
                onChange={handleFormChange}
              />
            </span>
          </StyledDiv>
        </>
      }
    </>
  );
};

type ConfigProps = {
  clientId: string;
  delivery: DeliveryData;
  updateClientDelivery: Function;
}

type ModalState = {
  title: string;
  subtext?: string;
  variant: ModalVariantType | undefined;
}

const DeliveryConfig: React.FC<ConfigProps> = (props) => {
  const { clientId, delivery, updateClientDelivery } = props;
  const { privateFetch } = useToken();

  const [modalState, setModalState] = useState<ModalState>({
    title: '',
    subtext: '',
    variant: undefined,
  });
  const [modalOpen, setModalOpen] = useState(false);
  const [formInput, setFormInput] = useState(delivery);

  const formRef = React.createRef<HTMLDivElement>();

  const handleChange = useCallback((e: any) => {
    setFormInput({ ...formInput, [e.target.id]: e.target.value });
  }, [formInput]);

  const saveVals = useCallback(async () => {
    const update = {};

    Object.keys(formInput).forEach((key: string) => {
      // @ts-ignore
      if (!delivery.hasOwnProperty(key) || formInput[key] !== delivery[key]) {
        // @ts-ignore
        update[key] = formInput[key];
      }
    });

    try {
      const url = `${apiURL}clients/${clientId}/deliveries/${delivery.id}`;
      const settings = {
          method: 'PUT',
          body: JSON.stringify(update),
      };
      const res = await privateFetch(url, settings);

      if (res.status >= 400) {
        EventBus.dispatch('notify', { variant: 'error', message: 'Failed to update delivery config' });
      }
      else {
        setModalState({
          title: 'Delivery Configuration Saved',
          subtext: 'You may need to reload the page to view updated credentials',
          variant: 'success',
        });
        setModalOpen(true);
      }
    }
    catch (err: any) {
      EventBus.dispatch('notify', { variant: 'error', message: 'Failed to update delivery config' });
    }
  }, [formInput, delivery, apiURL, clientId, privateFetch, EventBus]);

  const handleModalClose = useCallback(() => {
    setModalOpen(false);
    updateClientDelivery();
  }, [updateClientDelivery]);

  return (
    <FormWrap ref={formRef}>
      {modalOpen && (
        <Modal
          title={modalState.title}
          children={modalState.subtext}
          variant={modalState.variant}
          handleClose={handleModalClose}
        />
      )}
      <HeaderDiv>
       <h1>Delivery Configuration</h1>
       <ButtonWrap>
          <Button 
            onClick={saveVals}
            disabled={delivery === formInput}
          >Save</Button>
          <NavLink to={`/clients/${delivery.clientId}/deliveries/${delivery.id}`}>
            <Button
              variant="secondary-light"
            >
              Cancel
            </Button>
          </NavLink>
        </ButtonWrap>
        <LastUpdated>
          Last updated on{' '}
          {delivery.lastUpdatedAt && dateRender(delivery.lastUpdatedAt)}
        </LastUpdated>
      </HeaderDiv>
      <Form delivery={formInput} handleFormChange={handleChange} />
    </FormWrap>
  );
}

export default DeliveryConfig;
