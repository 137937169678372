import { palette } from '@stats/playbook-components';
import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';

import TableRow from './TableRow';

const StyledTable = styled.table`
  border-collapse: collapse;
  width: 100%;
`;

const StyledTH = styled.th`
  background-color: #000;
  color: #fff;
  font-size: 12px;
  padding: 8px 6px;
  text-align: left;
  line-height: 16px;
  box-sizing: border-box;

  &.sortable {
    cursor: pointer;
    position: relative;

    &:after,
    &:before {
      display: block;
      width: 0;
      height: 0;
      content: '';
      border: 4px solid transparent;
      position: absolute;
      right: 6px;
      border-radius: 4px;
    }

    &:before { 
      top: 10px; 
      border-top: 0;
      border-bottom-color: ${palette['sp-mid-gray']};
    }
    &:after {
      bottom: 10px;
      border-bottom: 0;
      border-top-color: ${palette['sp-mid-gray']};
    }
    
    &.asc:before {
      border-bottom-color: ${palette['jordan']};
    }

    &.desc:after {
      border-top-color: ${palette['jordan']};
    }

  }

`;

const StyledDiv = styled.div`
  display: grid;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  margin-top: 50px;
  font-weight: bold;
  font-size: 20px;
`;

const NoResults: React.FC<{}> = () => {
  return (
    <StyledDiv>No results found!</StyledDiv>
  );
}

type TableColumnProps = {
  key: string;
  label?: string;
  render?: Function;
  sortable?: boolean;
};

type TableProps = {
  columns: Array<TableColumnProps>;
  data: Array<any>;
  keyprop: any;
  subdataRender?: Function;
  subdataKey?: string;
};

type SortingProps = { 
  key: string;
  direction: 'asc' | 'desc' | ''
}

type SortableObject = {
  [key: string]: any
}

const Table: React.FC<TableProps> = (props) => {
  const { columns, data, keyprop, subdataRender, subdataKey } = props;
  const [rows, setRows] = useState(data.slice());
  const [sortBy, setSortBy] = useState<SortingProps>({
    key: '',
    direction: ''
  });

  const generateColumnHeader = (column:TableColumnProps) => {
    if( column.sortable ) {
      return <StyledTH key={column.key} onClick={()=> columnSort(column.key)} className={`sortable ${column.key === sortBy.key? sortBy.direction : ''}`} role="button">{column.label}</StyledTH>
    }
    return <StyledTH key={column.key}>{column.label}</StyledTH>
  }

  const columnSort = (key:string) => {
    const newState: SortingProps = {
      key: key,
      direction: 'asc'
    }

    if( sortBy.key === key ) {
      switch(sortBy.direction) {
        case 'asc':
          newState.direction = 'desc';
          break;
        case 'desc':
          newState.key = ''
          newState.direction = '';
          break;
        default:
          newState.direction = 'asc';
          break;
      }
    }

    setSortBy(newState);
  }

  const sortRows = useCallback(
    (A: SortableObject, B: SortableObject) => {
      const {
        key,
        direction
      } = sortBy;


      if( key === '' ) {
        return 0;
      }

      const a = direction === 'asc'? A.data[key] : B.data[key];
      const b = direction === 'asc'? B.data[key] : A.data[key];

      if( a < b ) { return -1; }
      if( a > b) { return 1; }
      return 0;
    },
    [sortBy]);

  useEffect(() => {
    if( sortBy.key !== '' ) {
      setRows(data.slice().sort(sortRows))
    } else {
      setRows(data.slice());
    }
  }, [sortBy, data, sortRows])

  return (
    <>
      <StyledTable>
        <thead>
          <tr>
            {subdataRender && <StyledTH>&nbsp;</StyledTH>}
            {columns.map(generateColumnHeader)}
          </tr>
        </thead>
        <tbody>
          {rows.map((row, idx) => (
            <TableRow
              key={row.data[keyprop]}
              rowkey={row.data[keyprop]}
              columns={columns}
              rowdata={row.data}
              subdata={row.subdata}
              subdataKey={subdataKey}
              subdataRender={subdataRender}
              odd={idx % 2 === 1}
            />
          ))}
        </tbody>
      </StyledTable>
      { rows.length === 0 && <NoResults /> }
    </>
  );
};

export default Table;
