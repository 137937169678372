import React from 'react';
import { Link, NavLink } from 'react-router-dom';
import styled from 'styled-components';

import { palette, Icon, typography } from '@stats/playbook-components';

const ClientDetailSidebar = styled.div`
  background: ${palette['sp-black']};
  padding: 48px 32px 0 32px;
  width: 272px;
  box-sizing: border-box;
`;

const ClientLink = styled(Link)`
  ${typography};
  display: block;
  font-size: 12px;
  line-height: 16px;
  font-weight: bold;
  color: ${palette['sp-white']};
  text-decoration: none;
  position: relative;
  padding-left: 28px;

  & svg {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    margin: auto;
  }
`;

const StyledNavLink = styled(NavLink)`
  ${typography};
  color: ${palette['sp-white']};
  text-decoration: none;
  display: block;
  padding: 8px 20px;
  margin: 0px -20px;
  font-size: 14px;
  border-radius: 100px;

  &.active {
    font-weight: bold;
    background-color: rgba(255, 255, 255, 0.2);
  }

  &.status {
    padding-right: 32px;
    position: relative;
    &::after {
      position: absolute;
      top: 0;
      bottom: 0;
      right: 20px;
      margin: auto;
      content: '';
      display: inline-block;
      width: 12px;
      height: 12px;
      background: ${palette['sp-dark-gray-25']};
      border-radius: 100%;
    }

    &.enabled::after {
      background: ${palette['serena']};
    }
  }
`;

const LinkText = styled.span`
  max-width: 100%;
  margin-right: 10px;
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const SectionTitle = styled.h2`
  ${typography};
  color: ${palette['sp-light-gray-75']};
  font-size: 12px;
  text-transform: uppercase;
`;

const ScrollWrap = styled.div`
  max-height: calc(100vh - 450px);
  overflow-y: auto;
  padding: 0 20px;
  margin: auto -20px;

  &::-webkit-scrollbar {
    display: none;
  }

  -ms-overflow-style: none;
`;

type ClientDeliveriesProps = {
  id: string;
  name: string;
  status: number;
  deliveryMethod: string;
};

type ClientSidebarProps = {
  name: string;
  deliveries: Array<ClientDeliveriesProps>;
  baseUrl: string;
};

// Determines if a NavLink is active or not
function isActive(_: any, location: any) {
  return !location.pathname.includes('deliveries');
}

const ClientSidebar: React.FC<ClientSidebarProps> = ({
  name,
  deliveries,
  baseUrl
}) => {
  return (
    <ClientDetailSidebar>
      <ClientLink to="/clients/overview">
        <Icon fill="sp-white" variant="chevron-left" /> BACK TO ALL CLIENTS
      </ClientLink>
      <h1>
        {name}
      </h1>
      <SectionTitle>SECTIONS</SectionTitle>
      <StyledNavLink to={baseUrl} isActive={isActive}>
        Client Summary
      </StyledNavLink>
      <SectionTitle>DELIVERIES</SectionTitle>
      <ScrollWrap>
        {deliveries.map((delivery) => (
          <StyledNavLink
            key={delivery.id}
            to={`${baseUrl}/deliveries/${delivery.id}`}
            className={'status ' + (delivery.status ? 'enabled' : 'disabled')}
            title={delivery.name}
          >
            <LinkText>
              {delivery.name}
              <br />
              <small>({delivery.deliveryMethod})</small>
            </LinkText>
          </StyledNavLink>
        ))}
      </ScrollWrap>
    </ClientDetailSidebar>
  );
};

export default ClientSidebar;
