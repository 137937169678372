import React, { useCallback, useState } from 'react';
import styled from 'styled-components';

import { palette } from '@stats/playbook-components';
import Checkbox from '@components/Checkbox';
import SearchBox from '@components/SearchBox';

import { DualListSelectorPaneProps, FileProps } from './DualListSelectorTypes';
import DualListSelectorItem from './DualListSelectorItem';

import Loader from '@components/Loader';


const DualListPaneWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

const DualListPaneInfo = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: 10px;
  line-height: 16px;
`;

const BoldText = styled.span`
  font-weight: bold;
`;

const SelectedCount = styled(BoldText)`
  flex-grow: 1;
  text-align: right;
`;

const SearchContainer = styled.div`
  padding: 16px 0px 0px 16px;
`;

const SelectAllContainer = styled.div`
  padding: 12px 16px;
  border-bottom: solid 1px ${palette['sp-light-gray-75']};
`;

const StyledContainer = styled.div`
  border-radius: 2px;
  border: solid 1px ${palette['sp-light-gray-50']};
  flex-grow: 1;
`;

const StyledUL = styled.ul`
  margin: 0;
  min-height: 195px;
  max-height: 195px;
  overflow: scroll;
  padding: 12px 8px;
  list-style: none;
`;

const DualListSelectorPane: React.FC<DualListSelectorPaneProps> = ({
  isChosen = false,
  selectedOptions = [],
  search = false,
  onOptionSelect = () => {},
  ...props
}) => {
  const [input, setInput] = useState<string>('');
  const { onSelectAll } = props;

  const displayOption = (option: FileProps, displayInput: string): boolean => {
    return option.name.toLocaleLowerCase().includes(displayInput.toLowerCase());
  };

  const handleSearchChangeEvent = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setInput(e.currentTarget.value);
  }, []);

  const handleSelectAll = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    if (onSelectAll) onSelectAll(e, isChosen);
  }, [onSelectAll, isChosen]);

  return (
    <DualListPaneWrapper className="selector-panel">
      <DualListPaneInfo>
        {props.title && (
          <span className="selector-panel__title">{props.title}</span>
        )}
        <SelectedCount>{selectedOptions.length} Selected</SelectedCount>
      </DualListPaneInfo>
      <StyledContainer>
        {search && (
          <SearchContainer>
            <SearchBox
              placeholder="Search"
              onChange={handleSearchChangeEvent}
          />
          </SearchContainer>
        )}
        {onSelectAll && (
          <SelectAllContainer>
            <Checkbox
              label={<BoldText>Select All</BoldText>}
              onChange={handleSelectAll}
              checked={
                selectedOptions.length > 0 &&
                selectedOptions.length === props.options?.length
              }
              disabled={props.options?.length === 0}
            />
          </SelectAllContainer>
        )}
        {props.isLoading
        ? <Loader />
        : <StyledUL>
            {props.options?.map((item, ind) => {
              if (displayOption(item, input)) {
                return (
                  <DualListSelectorItem
                    key={item.id || `file${isChosen ? '-chosen' : ''}-${ind}`}
                    label={item.name + (item.extension || '')}
                    orderIndex={ind}
                    isChosen={isChosen}
                    isSelected={selectedOptions.includes(ind)}
                    onOptionSelect={onOptionSelect}
                  />
                );
              } else {
                return null;
              }
            })}
          </StyledUL>
        }
      </StyledContainer>
    </DualListPaneWrapper>
  );
};

export default DualListSelectorPane;
export {};
