import { useAuth0 } from '@auth0/auth0-react';
import { useCallback } from 'react';

export const apiURL = process.env.REACT_APP_API_URL;

export const useToken = () => {
  const { getAccessTokenSilently } = useAuth0();
  const memoizedFn = useCallback(
    async (url: string, options?: any) => {
      const accessToken = await getAccessTokenSilently();
      if (!options) {
        options = {};
      }
      return fetch(url, {
        headers: {
          ...options.headers,
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
        ...options,
      });
    },
    [getAccessTokenSilently]
  );
  return {
    privateFetch: memoizedFn,
  };
};
