import React, { useEffect, useState, useCallback } from 'react';
import styled from 'styled-components';
import Checkbox from '@components/Checkbox';
import Table from '@components/Table';
import DateFilter from '@components/DateFilter';
import { DeliveryHistoryFilters } from '@components/DeliveryHistoryFilters';
import Pagination from '@components/Table/Pagination';
import Loader from '@components/Loader';
import { apiURL, useToken } from '@/helpers/apiURL';
import { parseFileSize } from '@/helpers/parsers';
import { getTimezoneOffset } from 'date-fns-tz';
import EventBus from '@/helpers/eventBus';

// Negative value in ms
const chicagoTimeOffset = getTimezoneOffset('America/Chicago');

type DeliveryHistoryData = {
  Items: [];
  TotalPages?: number;
  error?: string;
}

type DeliveryHistoryProps = {
  deliveryId?: string,
  clientId?: string
};

const Filters = styled.div`
  .filter {
    display: inline-block;
    margin-bottom: 20px;
    input {
      width: 260px;
      height: 32px;
      background-color: rgba(242, 242, 242, 1);
      margin-right: 24px;
      box-sizing: border-box;
      border-radius: 2px;
      border: solid 1px rgba(226, 226, 226, 1);
    }
  }
`;

const CheckBoxWrap = styled.div`
  label {
    display: inline-block;
  }
`;

// Parses time format coming from Timestream into seconds
function parseTime(t: string): number | string {
  if (!t) {
    return "-"
  }

  // Format: 0 00:00:00.249000000
  const [days, r] = t.split(" ");
  const [hours, minutes, s] = r.split(":");
  const [seconds, nanoseconds] = s.split(".");

  return (Number(nanoseconds) / 1000000000 + Number(seconds) * 1 + Number(minutes) * 60 + Number(hours) * 3600 + Number(days) * 86400).toFixed(3);
}

const TableWrap = styled.div`
  margin-top: 40px;
`;

const DeliveryHistoryList: React.FC<DeliveryHistoryProps> = (props) => {
  const { deliveryId, clientId } = props;
  const [deliveryHistory, setDeliveryHistory] = useState<DeliveryHistoryData>({
    Items: [],
    TotalPages: 1
  });
  const [deliveryStats, setDeliveryStats] = useState<DeliveryHistoryData>({
    Items: []
  });
  const [page, setPage] = useState(1);
  const [from, setFrom] = useState((new Date(Date.now() + chicagoTimeOffset)).setUTCHours(0, 0, 0, 0) - chicagoTimeOffset);
  const [to, setTo] = useState((new Date(Date.now() + chicagoTimeOffset)).getTime() - chicagoTimeOffset);
  const [isLoading, setIsLoading] = useState(true);
  const [errorCheckboxSelected, setErrorCheckboxSelected] = useState(false);
  const [fileFilter, setFileFilter] = useState('');
  const [typeFilter, setTypeFilter] = useState('');
  const [leagueFilter, setLeagueFilter] = useState('');
  const [pageSize, setPageSize] = useState(100);

  // Parses object returned from API
  const parseDeliveryHistory = (json: any, setter: Function) => {
    const data = {
      Items: json.Items,
      PageSize: json.PageSize,
      TotalPages: json.TotalPages,
    };

    setter(data);
  };

  // Retrieves data from API
  const { privateFetch } = useToken();
  const getHistory = useCallback(async () => {
    const timeoutId = setTimeout(() => setIsLoading(true), 500);
    let statsUrl = `${apiURL}metrics/stats?from=${from}&to=${to}`;
    let metricsUrl = `${apiURL}metrics?from=${from}&to=${to}&page=${page}&errorOnly=${errorCheckboxSelected}&size=${pageSize}`;
    
    if (clientId) {
      statsUrl += `&client=CLIENT${clientId}`;
      metricsUrl += `&client=CLIENT${clientId}`;
    }
    if (deliveryId) {
      statsUrl += `&delivery=${deliveryId}`;
      metricsUrl += `&delivery=${deliveryId}`;
    }
    if (fileFilter) {
      statsUrl += `&file=${encodeURIComponent(fileFilter)}`;
      metricsUrl += `&file=${encodeURIComponent(fileFilter)}`;
    }
    if (typeFilter) {
      statsUrl += `&type=${typeFilter}`;
      metricsUrl += `&type=${typeFilter}`;
    }
    if (leagueFilter) {
      statsUrl += `&league=${leagueFilter}`;
      metricsUrl += `&league=${leagueFilter}`;
    }

    // Request delivery history and metrics
    await Promise.all([privateFetch(statsUrl).then(async (response) => {
      const json = ((await response.json()) as unknown) as DeliveryHistoryData;
      if (json && !json.error) {
        parseDeliveryHistory(json, setDeliveryStats);
      } else {
        EventBus.dispatch('notify', { variant: 'error', message: 'Failed to load Delivery History Metrics' });
        setDeliveryHistory({
          Items: []
        });
      }
    }).catch(() => {
      EventBus.dispatch('notify', { variant: 'error', message: 'Failed to load Delivery History Metrics' });
    }), privateFetch(metricsUrl).then(async (response) => {
      const json = ((await response.json()) as unknown) as DeliveryHistoryData;
      if (json && !json.error) {
        parseDeliveryHistory(json, setDeliveryHistory);
      } else {
        EventBus.dispatch('notify', { variant: 'error', message: 'Failed to load Delivery History Metrics' });
        setDeliveryHistory({
          Items: [],
          TotalPages: 1
        });
      }
    }).catch(() => {
      EventBus.dispatch('notify', { variant: 'error', message: 'Failed to load Delivery History Metrics' });
    })]);
    
    setIsLoading(false);
    clearTimeout(timeoutId);
  }, [clientId, deliveryId, privateFetch, from, to, page, fileFilter, typeFilter, leagueFilter, errorCheckboxSelected, pageSize]);

  // Handles changes made to the to/from dates
  const handleDateChange = useCallback((e: any) => {
    setFrom(e.from);

    if (e.to > (new Date()).getTime() - chicagoTimeOffset) {
      setTo((new Date()).getTime() - chicagoTimeOffset);
    }
    else {
      setTo(e.to);
    }
    
    setPage(1);
  }, []);

  const handleCheckboxSelect = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setErrorCheckboxSelected(e.currentTarget.checked);
  }, []);

  const handleFilterSubmit = useCallback((filters: any) => {
    setFileFilter(filters.file);
    setTypeFilter(filters.type);
    setLeagueFilter(filters.league);
  }, []);

  const handlePageSizeChange = useCallback((e: number) => {
    setPage(1);
    setPageSize(e);
  }, []);

  useEffect(() => {
    getHistory();
  }, [getHistory]);

  // date: 1657898570000
  // filename: MLB_EVENTS.XML
  // duration: 923 ms
  // error: Error message

  const historyTableColumns = [
    {
      key: 'date',
      label: 'Delivery Time (CT)',
      render: (row: { time: string }) => {
        // Format: 2022-08-01 14:32:17.229000000
        // Reformat so new Date() knows time is in UTC
        // Expected Format: 2022-08-01T14:32:17.229000000Z
        const t = row.time.split(" ").join("T").concat('', 'Z');
        return new Date(t).toLocaleString('en-US', { timeZone: "America/Chicago"});
      },
    },
    {
      key: 'file',
      label: 'File Name',
      render: (row: { file: string, url: string, time: string }) => {
        const date = new Date(row.time.split(" ").join("T").concat('', 'Z'));
        // Allow links 7 days in the past to be viewed
        if (date.getTime() > Date.now() - 604800000) {
          return <a href={row.url} download>{row.file}</a>
        }
        else {
          return row.file;
        }
      },
    },
    {
      key: 'league',
      label: 'League',
      render: (row: { league: string }) => row.league,
    },
    {
      key: 'type',
      label: 'Type',
      render: (row: { type: string }) => row.type,
    },
    {
      key: 'created',
      label: 'Created',
      render: (row: { created: string }) => {
        const t = row.created.split(" ").join("T").concat('', 'Z');
        return new Date(t).toLocaleString('en-US', { timeZone: "America/Chicago"});
      },
    },
    {
      key: 'size',
      label: 'Size',
      render: (row: { size: number }) => parseFileSize(row.size),
    },
    {
      key: 'duration',
      label: 'Duration (s)',
      render: (row: { total: string }) => parseInt(row.total, 10) / 1000 || "-",
    },
    {
      key: 'process',
      label: 'Processing Time (s)',
      render: (row: { process_time: string }) => parseTime(row.process_time),
    },
    {
      key: 'delivery_time',
      label: 'Delivery Duration (s)',
      render: (row: { delivery_time: string }) => parseTime(row.delivery_time),
    },
    {
      key: 'error',
      label: 'Error Message',
      render: (row: { msg: string }) => row.msg || '-',
    }
  ];

  if (!deliveryId) {
    historyTableColumns.splice(1, 0, {
      key: 'delivery',
      label: 'Delivery',
      // @ts-ignore
      render: (row: { delivery: string }) => row.delivery
    })
  }

  if (!clientId) {
    historyTableColumns.splice(1, 0, {
      key: 'client',
      label: 'Client',
      // @ts-ignore
      render: (row: { client: string }) => row.client,
    })
  }

  const historyTableData = deliveryHistory?.Items?.map((item: any) => {
    // Set (hopefully) unique key for displaying items in table
    item.tableKey = `${item.delivery}_${item.time}`;
    return {
      data: item
    };
  });

  const statsTableColumns = [
    {
      key: 'files',
      label: 'Number of Files',
      render: (row: { numFiles: string }) => row.numFiles,
    },
    {
      key: 'errors',
      label: 'Number of Errors',
      render: (row: { numErrors: string }) => row.numErrors,
    },
    {
      key: 'duration',
      label: 'Average Duration (s)',
      render: (row: { avgTotalTime: string }) => parseInt(row.avgTotalTime, 10) / 1000 || "-",
    },
    {
      key: 'process',
      label: 'Average Processing Time (s)',
      render: (row: { avgProcessingTime: string }) => parseTime(row.avgProcessingTime),
    },
    {
      key: 'delivery',
      label: 'Average Delivery Time (s)',
      render: (row: { avgDeliveryTime: string }) => parseTime(row.avgDeliveryTime),
    },
  ];

  const statsTableData = deliveryStats?.Items?.map((item: { id: string }) => {
    return {
      data: item
    };
  });

  return (
    <>
    <Filters>
      <DateFilter
        fromDateVal={new Date(from)}
        toDateVal={new Date(to)}
        setDateFilter={handleDateChange}
        maxDaysInPast={14}
      />
      <DeliveryHistoryFilters
        filename={fileFilter} 
        type={typeFilter}
        league={leagueFilter}
        onSubmit={handleFilterSubmit} 
      />
      <CheckBoxWrap>
        <Checkbox
          checked={errorCheckboxSelected}
          label="Display Only Errors"
          id="error-checkbox"
          onChange={handleCheckboxSelect}
          size="medium"
        />
      </CheckBoxWrap>
    </Filters>
      {isLoading
        ? <Loader />
        : <>
            <TableWrap>
              <Table columns={statsTableColumns} data={statsTableData} keyprop="numFiles" />
            </TableWrap>
            <TableWrap>
              {(deliveryHistory.Items.length > 0 && deliveryHistory.TotalPages && deliveryHistory.TotalPages > 0) && (
                <Pagination
                  page={page}
                  totalPages={deliveryHistory.TotalPages || 1}
                  setPage={setPage}
                  pageSize={pageSize}
                  pageSizeOptions={[100,500,1000]}
                  setPageSize={handlePageSizeChange}
                />
              )}
              <Table columns={historyTableColumns} data={historyTableData} keyprop="tableKey" />
              {(deliveryHistory.Items.length > 0 && deliveryHistory.TotalPages && deliveryHistory.TotalPages > 0) && (
                <Pagination
                  page={page}
                  totalPages={deliveryHistory.TotalPages || 1}
                  setPage={setPage}
                  pageSize={pageSize}
                  pageSizeOptions={[100,500,1000]}
                  setPageSize={handlePageSizeChange}
                />
              )}
            </TableWrap>
          </>
      } 
    </>
  );
};

export default DeliveryHistoryList;
