import React from 'react';
import styled from 'styled-components';
import { palette, typography } from '@stats/playbook-components';
import { Notification } from '@components/Notification';

const StyledTile = styled.div`
  ${typography};

  margin: 24px 32px 32px;
  display: block;
  background-color: white;
  box-shadow: 0 0 8px 0 ${palette['sp-shadow']};
  flex-grow: 1;

  .tile-header,
  .tile-content {
    padding: 24px 24px 16px 24px;
    background-color: ${palette['sp-white']};
  }

  .tile-header {
    font-size: 21px;
    font-weight: 300;
    border-bottom: 1px solid ${palette['sp-light-gray-25']};
    border-radius: 4px 4px 0 0;
    display: flex;
    .tile-header-text {
      flex: 1 1;
    }
  }

  .tile-content {
    border-radius: 0 0 4px 4px;

    h1 {
      margin-bottom: 0;
      width: 50%;
      display: inline-block;
    }
  }

  &.secondary {
    .tile-header {
      color: ${palette['sp-white']};
      background-color: ${palette['sp-black']};
      font-size: 16px;
      font-weight: 700;
      padding: 0 24px;
      height: 40px;
      line-height: 40px;
      text-transform: uppercase;
    }
  }
`;

type TileType = {
  title?: string;
  childnav?: React.JSX.Element[] | React.JSX.Element;
};
export type TileProps = TileType & React.HTMLAttributes<HTMLDivElement>;

export const Tile: React.FC<TileProps> = (props) => {
  const { title, children, childnav } = props;

  return (
    <StyledTile className="primary">
      <Notification />
      {title && (
        <div className={'tile-header'}>
          <span className={'tile-header-text'}>{title}</span>
          <div className={'tile-header-subnav'}>{childnav}</div>
        </div>
      )}
      <div className={'tile-content'}>{children}</div>
    </StyledTile>
  );
};
