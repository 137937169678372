import React, { ChangeEventHandler } from 'react';
import styled from 'styled-components';
import { Icon, typography } from '@stats/playbook-components';

const StyledDiv = styled.div`
  position: relative;
`;

const StyledIcon = styled(Icon)`
  position: absolute;
  top: 8px;
  left: 10px;
`;

const StyledInput = styled.input`
  ${typography};
  background-color: #f2f2f2;
  border: solid 1px #e2e2e2;
  border-radius: 2px;
  margin-bottom: 10px;
  padding: 8px 4px 8px 30px;
  outline: 0;
  width: calc(100% - 36px);
`;

interface SearchBoxProps {
  onChange: ChangeEventHandler;
  placeholder?: string;
}
const SearchBox: React.FC<SearchBoxProps> = ({ onChange, placeholder }) => {
  return (
    <StyledDiv>
      <StyledIcon
        size="small"
        variant="search"
      />
      <StyledInput
        type="search"
        role="searchbox"
        onChange={onChange}
        placeholder={placeholder}
      />
    </StyledDiv>
  )
};

export default SearchBox;
