import React, { useCallback } from 'react';
import { apiURL, useToken } from '@/helpers/apiURL';
import styled from 'styled-components';
import { Modal } from '@stats/playbook-components';
import ReactDOM from 'react-dom';
import EventBus from '@/helpers/eventBus';

const StyledModal = styled(Modal)`
  && {
    width: 400px;
    height: 175px;
    border-top: 4px solid red;
  }
`;

type DeleteNoteModalProps = {
  clientId: string;
  note: { id: string; sk: string };
  handleClose: () => void;
};

const DeleteNoteModal: React.FC<DeleteNoteModalProps> = (props) => {
  const { handleClose, note, clientId } = props;

  const { privateFetch } = useToken();

  const deleteNote = useCallback(async () => {
    const url = `${apiURL}clients/${clientId}/notes/${note.sk}`;

    try {
      const settings = {
        method: 'DELETE',
        body: JSON.stringify({ id: clientId, noteId: note.sk }),
      };

      await privateFetch(url, settings);
      handleClose();
    } catch (e) {
      EventBus.dispatch('notify', { variant: 'error', message: 'Failed to delete note' });
      return e;
    }
  }, [clientId, note, handleClose, apiURL]);

  return (
    ReactDOM.createPortal(
      <StyledModal
        variant="error"
        title="Delete Client Note"
        handleClose={handleClose}
        modalAction={{
          text: 'Delete',
          onClick: deleteNote,
        }}
      >
        Are you sure you want to permanently delete this note?
      </StyledModal>,
      document.body
    )
  );
};

export default DeleteNoteModal;
