import React from 'react';
import { HeaderDiv } from './ClientSummaryDeliveryConfiguration/ConfigStyled';

import { DeliveryHistoryList } from '@components/DeliveryHistoryList';
import { Tile } from '@components/Tile';

const DeliveryHistory: React.FC<{}> = () => {
  return (
    <Tile>
      <HeaderDiv>
        <h1>Delivery History</h1>
      </HeaderDiv>
      <DeliveryHistoryList />
    </Tile>
  );
};

export default DeliveryHistory;
