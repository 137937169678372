import { createContext } from 'react';
// context for storing file filter menu items.

export type FileFiltersProps = {
  formats: string[];
  leagueDirectoryNames: string[];
  types: string[];
  languages: string[];
  outputTypes: string[];
}

export const fileFiltersDefault = {
  formats: [],
  leagueDirectoryNames: [],
  types: [],
  languages: [],
  outputTypes: []
}

const FileFiltersContext = createContext<FileFiltersProps>(fileFiltersDefault);

export default FileFiltersContext;