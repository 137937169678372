import React, { useCallback, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { apiURL, useToken } from '@/helpers/apiURL';
import EventBus from '@/helpers/eventBus';
import styled from 'styled-components';
import { Modal, Dropdown } from '@stats/playbook-components';
import { TextArea } from '@/components/TextArea';
import ReactDOM from 'react-dom';

const StyledModal = styled(Modal)`
  && {
    width: 400px;
    margin-top: 0px;
    height: auto;
    min-height: 400px;
    border-top: 4px solid red;
  }
`;

const StyledTextArea = styled(TextArea)`
  && {
    textarea {
      resize: none;
      width: 382px;
      min-height: 150px;
    }
  }
`;

const StyledDropdown = styled(Dropdown)`
  && {
    z-index: 9;
    margin-bottom: 15px;
  }
`;

type AddNoteModalContentProps = {
  disabledCallback: Function;
  setModalNote: Function;
  setDeliveryNameValue: Function;
  deliveries: Array<any>;
};

const AddNoteModalContent: React.FC<AddNoteModalContentProps> = (props) => {
  const {
    setModalNote,
    setDeliveryNameValue,
    disabledCallback,
    deliveries,
  } = props;

  const inputOnChange = useCallback((e: React.ChangeEvent<HTMLTextAreaElement>) => {
    disabledCallback(e.currentTarget.value.trim() === '');
    setModalNote(e.currentTarget.value.trim());
  }, []);

  return (
    <>
      <StyledDropdown
        label="Delivery"
        menuItems={deliveries?.map((delivery) => {
          return { value: delivery.name };
        })}
        onItemSelect={setDeliveryNameValue}
      />
      <StyledTextArea label="Enter Note" onChange={inputOnChange} required />
    </>
  );
};

type AddNoteModalProps = {
  clientId: string;
  handleClose: () => void;
  deliveries: Array<any>;
};

const AddNoteModal: React.FC<AddNoteModalProps> = (props) => {
  const { handleClose, clientId, deliveries } = props;
  const [disabled, setDisabled] = useState<boolean>(true);
  const [modalNote, setModalNote] = useState<string>('');
  const [deliveryName, setDeliveryName] = useState<string>('');
  const { user } = useAuth0();
  const { privateFetch } = useToken();

  const setDeliveryNameValue = useCallback((value: string) => {
    setDeliveryName(value);
  }, []);

  const addNote = useCallback(async () => {
    const url = `${apiURL}clients/${clientId}/notes`;
    let postBody = {
      // @ts-ignore
      user: user.name,
      text: modalNote,
      deliveryName: deliveryName,
    };

    try {
      const settings = {
        method: 'POST',
        body: JSON.stringify(postBody),
      };

      await privateFetch(url, settings);
      handleClose();
    } catch (e) {
      EventBus.dispatch('notify', { variant: 'error', message: 'Failed to create note' });
      return e;
    }
  }, [apiURL, clientId, useAuth0, modalNote, deliveryName, handleClose]);

  return (
    ReactDOM.createPortal(
      <StyledModal
        title="Add Note"
        handleClose={handleClose}
        modalAction={{
          text: 'Create',
          onClick: addNote,
          disabled: disabled,
        }}
      >
        <AddNoteModalContent
          setModalNote={setModalNote}
          setDeliveryNameValue={setDeliveryNameValue}
          disabledCallback={setDisabled}
          deliveries={deliveries}
        />
      </StyledModal>,
      document.body
    )
  );
};

export default AddNoteModal;
