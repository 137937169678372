export function parseFileSize(size: number): string {
  if (size === undefined || size.toString() === '0') {
    return "N/A";
  }
  else if (size / 1024 < 1) {
    return `${parseInt(size.toString(), 10)} bytes`;
  }
  else if (size / (1024 * 1024) < 1) {
    return `${parseInt((size / 1024).toString(), 10)} KB`;
  }
  else if (size / (1024 * 1024 * 1024) < 1) {
    return `${parseFloat((size / (1024 * 1024)).toString()).toFixed(1)} MB`;
  }
  else {
    return `${parseFloat((size / (1024 * 1024 * 1024)).toString()).toFixed(1)} GB`;
  }
}
