import React, { useCallback, useContext } from 'react';
import styled from 'styled-components';
import { Dropdown } from '@stats/playbook-components';
import DeliveryMethodsContext from '@/DeliveryMethodsContext';

const StyledDropDownWrapper = styled.div`
  && {
    padding: 15px 0 10px 0;

    > div {
      display: inline-block;

    > div {
      margin-right: 24px;
      display: inline-block;
      width: auto;
      > div {
        width: auto;
      }
    }
  }
  }
`;

type FilterProps = {
  setPage: Function;
  setEnabledFilter: Function;
  setMethodFilter: Function;
};

const DeliveryFilter: React.FC<FilterProps> = (props) => {
  const { setPage, setEnabledFilter, setMethodFilter } = props;

  const statusMenuItems = [
    { value: '', name: 'Any' },
    { value: '0', name: 'Disabled' },
    { value: '1', name: 'Enabled' }
  ];
  const deliveryMethods = useContext(DeliveryMethodsContext);
  const deliveryMethodMenuItems = [
    {value: '', name: 'Any'}, 
    ...deliveryMethods.map(({method}) => ({value: method}))
  ];

  // Handles selecting of status filter
  const handleSelectStatus = useCallback((value: string) => {
    setPage(1);
    setEnabledFilter(value);
  }, []);

  // Handles selecting of method filter
  const handleSelectMethod = useCallback((value: string) => {
    setPage(1);
    setMethodFilter(value);
  }, []);

  return (
    <StyledDropDownWrapper>
      <div>
        <Dropdown
        onItemSelect={handleSelectStatus}
        value={'Any'}
        label="Status"
        variant="secondary"
        menuItems={statusMenuItems}
        excludeDeselectOption
      />
      </div>
      <div style={{'overflow':'visible'}}>
      <Dropdown
        onItemSelect={handleSelectMethod}
        label="Delivery Method"
        variant="secondary"
        value={'Any'}
        menuItems={deliveryMethodMenuItems}
        excludeDeselectOption
      />
      </div>
    </StyledDropDownWrapper>
  );
};

export default DeliveryFilter;
