import React, { useEffect, useState, useCallback, useContext } from 'react';
import styled from 'styled-components';
import Loader from '@components/Loader';
import Table from '@components/Table';
import Pagination from '@components/Table/Pagination';
import { Tile } from '@components/Tile';
import { Dropdown } from '@stats/playbook-components';
import SearchBox from '@components/SearchBox';
import { Link } from 'react-router-dom';
import { apiURL, useToken } from '@/helpers/apiURL';
import { ReactComponent as IconEdit } from '@/assets/edit_icon.svg';
import FileFiltersContext from '@/FileFiltersContext';

type OverviewFilesProps = {};

type FileData = {
  Items: [];
  CurrentPage: number;
  PageSize: number;
  TotalPages: number;
};

const StyledDropDownWrapper = styled.div`
  && {
    padding: 15px 0 10px 0;

    > div {
      margin-right: 24px;
      display: inline-block;
      width: auto;
      > div {
        width: auto;
      }
    }
  }
`;

const StyledLink = styled(Link)`
  float: right;
  margin-right: 15px;
`;

// Sorts two strings alphabetically
function sortAlphabetical(a: string, b: string) {
  if (a < b) {
    return -1;
  }
  else if (b < a) {
    return 1;
  }
  else {
    return 0;
  }
}

type TableFiltersProps = {
  setLeagueDirectoryName: Function;
  setFileFormat: Function;
  setContentType: Function;
};

//TABLE FILTERS
const TableFilters: React.FC<TableFiltersProps> = (props) => {
  const { setLeagueDirectoryName, setFileFormat, setContentType } = props;

  const fileFilters = useContext(FileFiltersContext);
  const menuItems = (str: string, ind: number) => ({ value: str });

  const leagueDirectories = fileFilters.leagueDirectoryNames.map(menuItems);
  const fileFormats = fileFilters.formats.map(menuItems);
  const fileTypes = fileFilters.types.map(menuItems);

  return (
    <StyledDropDownWrapper>
      <Dropdown
        label="League Directories"
        onItemSelect={setLeagueDirectoryName}
        menuItems={leagueDirectories}
        value={'arge' || leagueDirectories[0].value}
        className="dropdown"
        variant="secondary"
        excludeDeselectOption
      />
      <Dropdown
        label="File Format"
        onItemSelect={setFileFormat}
        menuItems={fileFormats}
        value={'XML' || fileFormats[0].value}
        className="dropdown"
        variant="secondary"
        excludeDeselectOption
      />
      <Dropdown
        label="File Type"
        onItemSelect={setContentType}
        menuItems={fileTypes}
        value={'live' || fileTypes[0].value}
        className="dropdown"
        variant="secondary"
        excludeDeselectOption
      />
    </StyledDropDownWrapper>
  );
};

const OverviewFiles: React.FC<OverviewFilesProps> = () => {
  const [files, setFiles] = useState<FileData>({
    Items: [],
    CurrentPage: 1,
    PageSize: 20,
    TotalPages: 1,
  });

  const [page, setPage] = useState(1);
  const [search, setSearch] = useState('');
  const [leagueDirectoryName, setLeagueDirectoryName] = useState('arge');
  const [fileFormat, setFileFormat] = useState('XML');
  const [contentType, setContentType] = useState('live');
  const [isLoading, setIsLoading] = useState(false);
  const { privateFetch } = useToken();

  const getFiles = useCallback(async () => {
    const timeoutId = setTimeout(() => setIsLoading(true), 500);
    const url = `${apiURL}files/?page=${page}&size=20&search=${search}&leagueDirectoryName=${leagueDirectoryName}&format=${fileFormat}&type=${contentType}`;

    const response = await privateFetch(url);
    const json = await response.json() as FileData;
    if (json) {
      setFiles(json);
    }

    setIsLoading(false);
    clearTimeout(timeoutId);
  }, [page, search, leagueDirectoryName, fileFormat, contentType, privateFetch, apiURL ]);

  // Always reset page to 1 whenever filters change
  useEffect(() => {
    setPage(1);
  }, [leagueDirectoryName, fileFormat, contentType]);

  useEffect(() => {
    getFiles();
  }, [getFiles]);

  const tableColumns = [
    {
      key: 'name',
      label: 'Name',
      render: (row: { name: string; extension: string }) =>
        row.name + (row.extension || ''),
    },
    {
      key: 'leagueDirectoryName',
      label: 'League',
      render: (row: { leagueDirectoryName: string }) => row.leagueDirectoryName,
    },
    {
      key: 'format',
      label: 'Format',
      render: (row: { format: string }) => row.format,
    },
    {
      key: 'type',
      label: 'Content Type',
      render: (row: { type: string }) => row.type,
    },
    {
      key: 'deliveriesCount',
      label: 'Deliveries',
      render: (row: { deliveriesCount: string }) => row.deliveriesCount,
    },
    {
      key: 'url',
      label: 'API Url',
      render: (row: { url: string }) => row.url,
    },
    {
      key: 'status',
      label: 'Status',
      render: (row: { status: number }) => {
        return row.status === 1 ? 'Enabled' : 'Disabled';
      },
    },
  ];

  const tableData = files?.Items.map(
    (item: { id: string; deliveriesCount: number }) => {
      return {
        data: item,
        subdata:
          item.deliveriesCount > 0 &&
          (() =>
            privateFetch(`${apiURL}files/${item.id}`).then(async (response) => {
              const json = await response.json();
              if (json) {
                const deliveries = json.deliveries;
                deliveries.sort((a: any, b: any) => {
                  // Sort first by status
                  if (a.status !== b.status) return b.status - a.status
                  // Sort by alphabetical
                  else return sortAlphabetical(a.name, b.name)
                });
                // Set the unique key of the delivery for the table
                deliveries.forEach((d: any) => d.tableKey = `${d.id}CLIENT${d.clientId}`);
                return deliveries;
              }
            })),
      };
    }
  );

  const renderSubData = useCallback((row: any) => {
    let display = <span>{`${row.name} (${row.deliveryMethod})`}</span>;
    if (row.status === 0) {
      display = <span><s>{`${row.name} (${row.deliveryMethod})`}</s> <b>DISABLED</b></span>;
    }
    return (
      <>
        {display}
        <StyledLink to={`/clients/${row.clientId}/deliveries/${row.id}`}>
          <IconEdit role="edit-icon"/>
        </StyledLink>
      </>
    );
  }, []);

  const handleSearch = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.currentTarget.value);
    if (page > 1) {
      setPage(1);
    }
  }, [page]);

  return (
    <Tile title="Files">
      <SearchBox
        onChange={handleSearch}
        placeholder="Search Files"
      />

      <TableFilters
        setLeagueDirectoryName={setLeagueDirectoryName}
        setFileFormat={setFileFormat}
        setContentType={setContentType}
      />

      { isLoading
        ? <Loader />
        : <>
            <Table
              columns={tableColumns}
              data={tableData}
              subdataKey="tableKey"
              subdataRender={renderSubData}
              keyprop="id"
            />

            {files?.TotalPages > 0 && (
              <Pagination
                page={page}
                totalPages={files?.TotalPages}
                setPage={setPage}
              />
            )}
          </>
      }
    </Tile>
  );
};

export default OverviewFiles;
