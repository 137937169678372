import React, { useCallback, useState } from 'react';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import { Input, InputProps } from '@stats/playbook-components';
import styled from 'styled-components';

const StyledWrapper = styled.div`
  position: relative;

  .state-icon {
    position: absolute;
    right: 35px;
    top: 30px;
    cursor: pointer;
  }
`;

const PasswordInput: React.FC<InputProps> = (props) => {
  const [hidden, setHidden] = useState(true);

  const onClick = useCallback(() => {
    setHidden(!hidden);
  }, [hidden]);

  return (
    <StyledWrapper>
      <Input
        type={(hidden) ? 'password' : 'text'}
        role={(hidden) ? 'password' : 'textbox'}
        {...props}
      />
      { hidden
        ? <FaEye
            className={'state-icon'}
            role="icon"
            onClick={onClick}
          />
        : <FaEyeSlash
            className={'state-icon'}
            role="icon"
            onClick={onClick}
          />
      }
    </StyledWrapper>
  )
};

export default PasswordInput;