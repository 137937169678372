import React, { useEffect, useState, useCallback } from 'react';
import styled from 'styled-components';
import Loader from '@components/Loader';
import Table from '@components/Table';
import { ConfirmSwitch } from '@components/ConfirmSwitch';
import Pagination from '@components/Table/Pagination';
import { Tile } from '@components/Tile';
import DeliveryFilter from '@components/DeliveryFilter';
import { Icon } from '@stats/playbook-components';
import SearchBox from '@components/SearchBox';

import { useHistory } from 'react-router-dom';
import { apiURL, useToken } from '@/helpers/apiURL';

type ClientData = {
  Items: [];
  CurrentPage: number;
  PageSize: number;
  TotalPages: number;
};

const TableWrapper = styled.div`
  && {
    table {
      thead {
        tr {
          th:first-child {
            width: 32px;
          }
          th:nth-child(2) {
            width: 304px;
          }
          th:nth-child(3) {
            width: 180px;
          }
          th:nth-child(5) {
            width: 32px;
          }
          th:last-child {
            width: 32px;
          }
        }
      }
    }
  }
`;

const StyledIcon = styled(Icon)`
  float: right;
  cursor: pointer;
`;

const IconPointer = styled(Icon)`
  cursor: pointer;
`;

const OverviewClients: React.FC<{}> = () => {
  const [clients, setClients] = useState<ClientData>({
    Items: [],
    CurrentPage: 1,
    PageSize: 20,
    TotalPages: 1,
  });
  const history = useHistory();

  const { privateFetch } = useToken();
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState('');
  const [deliveryMethod, setDeliveryMethod] = useState('');
  const [status, setStatus] = useState('');
  const [isLoading, setIsLoading] = useState(true);

  const setEnabledFilter = useCallback((value: string) => {
    return setStatus(value);
  }, []);
  const setMethodFilter = useCallback((value: string) => {
    return setDeliveryMethod(value);
  }, []);

  const getClients = useCallback(async () => {
    const timeoutId = setTimeout(() => setIsLoading(true), 500);

    // Build the URL
    const size = 20;
    let url = `${apiURL}clients/?page=${page}&size=${size}&name=${search}`;
    if (deliveryMethod !== '') url += `&deliveryMethod=${deliveryMethod}`;
    if (status !== '') {
      url += `&status=${status}`;
    }

    const res = await privateFetch(url);
    const json = await res.json();
    setClients(json);

    setIsLoading(false);
    clearTimeout(timeoutId);
  }, [page, search, deliveryMethod, status, privateFetch]);

  useEffect(() => {
    getClients()
  }, [getClients]);

  const tableColumns = [
    {
      key: 'name',
      label: 'Name',
      render: (row: { name: string }) => row.name,
    },

    {
      key: 'deliveryitemcount',
      label: 'Enabled Delivery Items',
      render: (row: { enabledDeliveryItems: number }) =>
        row.enabledDeliveryItems,
    },
    {
      key: 'enabledmethods',
      label: 'Enabled Delivery Methods',
      render: (row: { enabledDeliveryMethods: Array<string> }) =>
        row.enabledDeliveryMethods.join(', '),
    },
    {
      key: 'enabled',
      label: 'Enabled',
      render: (row: { enabled: typeof ConfirmSwitch }) => row.enabled,
    },
    {
      key: 'detaillink',
      label: '',
      render: (row: { id: string }) => (
        <IconPointer
          size="medium"
          variant="edit"
          onClick={() => {
            history.push(`/clients/${row.id}`);
          }}
          role="edit-icon"
        />
      ),
    },
  ];

  const enableToggle = useCallback(async (
    value: {
      current: { querySelector: Function } | null;
    },
    item: { id: string; name: string }
  ) => {
    const enabled = value.current?.querySelector('input').defaultChecked;

    const url = `${apiURL}clients/${item.id}`;

    try {
      const settings = {
        method: 'PUT',
        body: JSON.stringify({ status: enabled ? 0 : 1, name: item.name }),
      };

      const deliveryStatus = await privateFetch(url, settings);
      return deliveryStatus.json();
    } catch (e) {
      return e;
    }
  }, [apiURL, privateFetch]);

  const tableData = clients?.Items.map(
    (item: {
      id: string;
      enabledDeliveryItems: number;
      status: number;
      name: string;
    }) => {
      const checkboxRef = React.createRef<HTMLElement>();
      const revised = {
        ...item,
        ...{
          enabled: (
            <span ref={checkboxRef}>
              <ConfirmSwitch
                defaultChecked={item.status === 1}
                onClick={() => {
                  enableToggle(checkboxRef, item);
                }}
                modalTitle="Disable Client"
                modalText="Are you sure you want to disable this client? This will disable all
                deliveries, and the client will no longer receive any files."
                modalConfirmText="Disable"
              />
            </span>
          ),
        },
      };

      return {
        data: revised,
        subdata:
          item.enabledDeliveryItems > 0 &&
          (() =>
            privateFetch(
              `${apiURL}clients/${item.id}/deliveries`
            ).then(async (response) => {
              const json = ((await response.json()) as unknown) as any;
              if (json) {
                return json.Items;
              }
            })),
      };
    }
  );

  const handleSearchBoxChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    if (page > 1) {
      setPage(1);
    }

    setSearch(e.currentTarget.value);
  }, [page]);

  const subdataRender = useCallback((row: any) => {
    return (
      <>
        <span>{`${row.name} (${row.deliveryMethod})`}</span>
        <StyledIcon
          size="medium"
          variant="edit"
          onClick={() => {
            history.push(`/clients/${row.clientId}/deliveries/${row.id}`);
          }}
          role="edit-icon"
        />
      </>
    );
  }, [history]);

  return (
    <Tile title="Clients">
      <SearchBox
        onChange={handleSearchBoxChange}
        placeholder="Search Clients"
      />
      <DeliveryFilter
        setPage={setPage}
        setEnabledFilter={setEnabledFilter}
        setMethodFilter={setMethodFilter}
      />
      
      { isLoading
        ? <Loader />
        : <>
            <TableWrapper>
              <Table
                columns={tableColumns}
                data={tableData}
                keyprop="id"
                subdataKey="id"
                subdataRender={subdataRender}
              />
            </TableWrapper>

            {clients?.TotalPages > 0 && (
              <Pagination
                page={page}
                totalPages={clients?.TotalPages}
                setPage={setPage}
              />
            )}
          </>
      }
    </Tile>
  );
};

export default OverviewClients;
